import React, { useState, Fragment, useEffect } from "react";
import MainContainer from "../../components/maincontainer";
import Header from "../../components/header";
import PrimaryBtn, { TabBtn } from "../../components/buttons";
import { RichTextEditor } from "../../components/inputs";
import { CiCirclePlus } from "react-icons/ci";
import { HiTrash } from "react-icons/hi";
import { toast } from "react-toastify";
import useErrorStore from "../../data/Stores/errorstore";
import { FaEdit } from "react-icons/fa";
import { apiCall } from "../../data/Stores/AuthStore";
import { MdDelete } from "react-icons/md";
import { useTriviaStore } from "../../data/Stores/loggerStore";
import { LoadMore, MainPaginate } from "../../components/Pagination";
import DOMPurify from "dompurify";
import ModalContainer from "../../components/modals/modalcontainer";

export const createMarkup = html => {
	return {
		__html: DOMPurify.sanitize(html),
	};
};

const Trivia = () => {
	return (
		<div>
			<MainContainer>
				<Header text={"Trivia"} />
				<MainSection />
			</MainContainer>
		</div>
	);
};

const MainSection = () => {
	const [tab, setTab] = useState("set");
	return (
		<section className="tw-w-full tw-p-8 tw-mt-10 tw-bg-white">
			<div className="tw-flex tw-gap-3">
				<TabBtn
					onClick={() => setTab("set")}
					text={"Set Questions"}
					css={
						tab === "set"
							? "tw-border-none"
							: "tw-border-0.5 tw-border-[#0A1C2DB2]"
					}
					bg={tab === "set" ? "#1C466C" : "white"}
					color={tab === "set" ? "white" : "#1C466C"}
				/>
				<TabBtn
					onClick={() => setTab("manage")}
					text={"Manage Questions"}
					css={
						tab === "manage"
							? "tw-border-none"
							: "tw-border-0.5 tw-border-[#0A1C2DB2]"
					}
					bg={tab === "manage" ? "#1C466C" : "white"}
					color={tab === "manage" ? "white" : "#1C466C"}
				/>
			</div>
			{tab === "set" && <SetQuestion setTab={setTab} />}
			{tab === "manage" && <ManageQuestions />}
		</section>
	);
};

function hasUniqueValue(array, propertyName, desiredValue) {
	let count = 0;

	for (let i = 0; i < array.length; i++) {
		if (array[i][propertyName]) {
			count++;
			if (count > 1) {
				return false; // More than one object has the desired value
			}
		}
	}

	return count === 1; // Only one object has the desired value
}

const SetQuestion = ({ setTab }) => {
	let init = { questionType: "multiple" },
		[questions, setQuestions] = useState([init]),
		{ returnErrors } = useErrorStore(),
		[loading, setLoading] = useState(null);

	const handleInputChangeForMutipleItem = (event, index, field, option) => {
		if (field !== "question") var { value } = event?.target;

		let itemValue = ["answer", "questionType", "question"]?.includes(field)
			? option
			: value;

		// if (field === "price") {
		// 	let parsedValue = parseFloat(itemValue);
		// 	if (Number.isInteger(parsedValue)) {
		// 		parsedValue = parsedValue.toFixed(0);
		// 		itemValue = parsedValue;
		// 	}
		// }

		setQuestions(prevRows => {
			const newRows = [...prevRows];
			newRows[index][field] = itemValue;
			return newRows;
		});
	};

	const handleDeleteRowForMutipleItem = index => {
		setQuestions(prevRows => {
			const newRows = [...prevRows];
			newRows.splice(index, 1);
			return newRows;
		});
	};
	const addRowForMutipleItem = () => {
		const newRow = init;
		setQuestions([...questions, newRow]);
	};
	// console.log({ questions });
	let handleSubmit = async e => {
		e?.preventDefault();
		let errArr1 = [],
			returnedData = [];
		if (questions?.length === 0) return toast.info("Questions required");
		console.log({ questions });
		for (let e = 0; e < questions.length; e++) {
			let element = questions?.[e],
				newOption = [];
			element.options = [];
			element?.options?.push({
				option: element?.option_a || "",
				isCorrect: element?.answer === "option_a",
				file: element?.option_afile,
				optionType: element?.option_afilequestionType || "text",
			});
			element?.options?.push({
				option: element?.option_b || "",
				isCorrect: element?.answer === "option_b",
				file: element?.option_bfile,
				optionType: element?.option_bfilequestionType || "text",
			});
			if (element?.questionType === "multiple") {
				element?.options?.push({
					option: element?.option_c || "",
					isCorrect: element?.answer === "option_c",
					file: element?.option_cfile,
					optionType: element?.option_cfilequestionType || "text",
				});
				element?.options?.push({
					option: element?.option_d || "",
					isCorrect: element?.answer === "option_d",
					file: element?.option_dfile,
					optionType: element?.option_dfilequestionType || "text",
				});
			}
			if (!element?.question)
				errArr1.push({
					message: `Question ${e + 1} is required`,
					path: "question",
				});
			if (!element?.options)
				errArr1.push({
					message: `Question ${e + 1} options is required`,
					path: "options",
				});
			// if (element?.questionType)
			// 	if (element?.questionType !== "text") {
			// 		if (!element?.file)
			// 			errArr1.push({
			// 				message: `Question ${e + 1} file is required`,
			// 				path: "file",
			// 			});
			// 	}

			if (!hasUniqueValue(element?.options, "isCorrect", true)) {
				errArr1.push({
					message: `Question ${e + 1} options requires a single answer`,
					path: "isCorrect",
				});
			}
			// console.log({ element });
			for (let o = 0; o < element?.options.length; o++) {
				let elementOption = element?.options?.[o];
				if (!elementOption?.option && !elementOption?.file)
					errArr1.push({
						message: `Question ${e + 1} Option ${o + 1} is required`,
						path: "option",
					});
				// if (elementOption?.optionType)
				// 	if (elementOption?.optionType !== "text") {
				// 		if (!elementOption?.file)
				// 			errArr1.push({
				// 				message: `Question ${e + 1} Option ${o + 1} file is required`,
				// 				path: "file",
				// 			});
				// 	}
				if (elementOption?.isCorrect) element.correctOptionIndex = o + 1;
				newOption?.push({ ...elementOption });
			}
			returnedData?.push({ ...element, options: newOption });
		}
		console.log({ returnedData });
		if (errArr1?.length > 0) return returnErrors({ error: errArr1 });
		setLoading(true);
		let newState = { questions: returnedData };

		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/question/batch-create`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			setTab("manage");
			return;
		}
		setLoading(false);
	};

	return (
		<>
			<div className="tw-mt-6">
				<h4 className="tw-text-base tw-font-bold tw-text-primary">
					Add Question(s)
				</h4>
				<form action="" className="tw-mt-6">
					<div className="tw-w-1/2 tw-space-y-4">
						{questions?.map((question, i) => (
							<Fragment key={i}>
								<RichTextEditor
									label={`Question ${i + 1}`}
									value={question?.question}
									setState={event =>
										handleInputChangeForMutipleItem(event, i, "question", event)
									}
								/>
								<div>
									<h4 className="tw-text-base tw-font-bold tw-text-primary">
										Options
									</h4>
									<div className="tw-mt-4">
										<h6 className="tw-text-base tw-font-medium tw-text-newblue">
											Type
										</h6>
										<div className="tw-flex tw-gap-3 ">
											<div className="tw-h-12 tw-w-44 tw-bg-[#E9EEF2] tw-border-0.5 tw-border-[#FFFFFF33] tw-rounded tw-flex tw-items-center tw-px-3 tw-justify-between">
												<div>
													<small className="tw-text-newblue tw-font-normal tw-text-sm">
														Multiple Answers
													</small>
												</div>
												<input
													type="radio"
													name={`questionType${i}`}
													className="tw-size-4 tw-mt-1"
													id=""
													value={question?.questionType}
													onChange={event =>
														handleInputChangeForMutipleItem(
															event,
															i,
															"questionType",
															"multiple"
														)
													}
													checked={question?.questionType === "multiple"}
												/>
											</div>
											<div className="tw-h-12 tw-w-44 tw-bg-[#E9EEF2] tw-border-0.5 tw-border-[#FFFFFF33] tw-rounded tw-flex tw-items-center tw-px-3 tw-justify-between">
												<div>
													<small className="tw-text-newblue tw-font-normal tw-text-sm">
														Single Answer
													</small>
												</div>
												<input
													type="radio"
													name={`questionType${i}`}
													className="tw-size-4 tw-mt-1"
													id=""
													value={question?.questionType}
													onChange={event =>
														handleInputChangeForMutipleItem(
															event,
															i,
															"questionType",
															"single"
														)
													}
													checked={question?.questionType === "single"}
												/>
											</div>
										</div>
										<div className="tw-mt-5 tw-w-full tw-space-y-4">
											<OptionsInput
												option={"option_a"}
												question={question}
												index={i}
												handleInputChangeForMutipleItem={
													handleInputChangeForMutipleItem
												}
												title="A"
											/>
											<OptionsInput
												option={"option_b"}
												question={question}
												index={i}
												handleInputChangeForMutipleItem={
													handleInputChangeForMutipleItem
												}
												title="B"
											/>
											{question?.questionType === "multiple" && (
												<>
													<OptionsInput
														option={"option_c"}
														question={question}
														index={i}
														handleInputChangeForMutipleItem={
															handleInputChangeForMutipleItem
														}
														title="C"
													/>
													<OptionsInput
														option={"option_d"}
														question={question}
														index={i}
														handleInputChangeForMutipleItem={
															handleInputChangeForMutipleItem
														}
														title="D"
													/>
												</>
											)}
										</div>
										{questions?.length > 1 && (
											<button
												onClick={() => handleDeleteRowForMutipleItem(i)}
												className="tw-mb-4 tw-mt-2 tw-px-4 tw-py-2 tw-bg-scblue tw-rounded-full tw-text-white tw-w-12 tw-h-12 tw-flex tw-justify-center tw-items-center tw-text-lg tw-ms-auto">
												<HiTrash className="icon tw-font-bold" size={30} />
											</button>
										)}
									</div>
								</div>
							</Fragment>
						))}
						<div
							onClick={addRowForMutipleItem}
							className="tw-flex tw-gap-3 tw-items-center tw-cursor-pointer">
							<CiCirclePlus />
							<div>
								<strong className="tw-font-medium tw-text-xs tw-text-black">
									Add question
								</strong>
							</div>
						</div>
						<div className="tw-mt-8">
							<PrimaryBtn
								text={"Continue"}
								width={
									"tw-bg-scblue tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white"
								}
								onClick={handleSubmit}
								loading={loading}
							/>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

const OptionsInput = ({
	title,
	question,
	option,
	handleInputChangeForMutipleItem,
	index,
}) => {
	return (
		<div>
			<div>
				<strong className="tw-text-base tw-capitalize tw-font-medium tw-text-black">
					Option {title}
				</strong>
			</div>
			<div className="tw-mt-2 tw-w-full tw-relative tw-h-12">
				{/* <div className="tw-absolute tw-top-3 tw-left-4">
					<input type="checkbox" name="" className="tw-size-4" id="" />
				</div> */}
				<input
					type="text"
					placeholder="Answer...."
					className="tw-w-full tw-h-full tw-pl-2 tw-bg-transparent tw-border-0.5 tw-border-[#EEEEEE] tw-text-base tw-font-medium tw-text-primary tw-rounded-md"
					value={question?.[option]}
					onChange={event =>
						handleInputChangeForMutipleItem(event, index, option)
					}
				/>
				<div className="tw-absolute tw-top-2 tw-right-4">
					<div className="tw-flex tw-items-center tw-gap-2">
						<div>
							<small className="tw-text-sm tw-font-normal tw-text-[#002224]">
								Correct Answer
							</small>
						</div>
						<input
							className="tw-size-3 tw-mt-1"
							id=""
							type="radio"
							name={`answer${option}${index}`}
							value={question?.answer}
							checked={question?.answer === option}
							onChange={event =>
								handleInputChangeForMutipleItem(event, index, "answer", option)
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const OptionsComp = ({ value, title, isCorrect }) => {
	return (
		<div>
			<div>
				<strong className="tw-text-base tw-capitalize tw-font-medium tw-text-black">
					Option {title}
				</strong>
			</div>
			<div className="tw-mt-2 tw-w-full tw-relative tw-h-12">
				<input
					type="text"
					placeholder="Answer...."
					className="tw-w-full tw-h-full tw-pl-2 tw-bg-transparent tw-border-0.5 tw-border-[#EEEEEE] tw-text-base tw-font-medium tw-text-primary tw-rounded-md"
					value={value}
					readOnly
				/>
			</div>
		</div>
	);
};

const ManageQuestions = () => {
	const questions = [
		{
			title: "What is your name ?",
			options: [
				{
					option:
						"Paul faksdlfj klfjasd f dfajsd dfjk asdfdfklas jkl skdjf asj fksdlf jsdklfj sdklfj asdklf",
					isCorrect: true,
				},
				{
					option: "Temiloluwa",
					isCorrect: false,
				},
			],
		},
	];

	let [datum, setDatum] = useState({ docs: questions }),
		[loading, setLoading] = useState(null),
		{ isFound, mainSearch, data, getLogger } = useTriviaStore(),
		[search] = useState("");

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall("get", "/api/v1/question", null, getLogger);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [range] = useState(10),
		[page, setPage] = useState(1),
		[itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	let handleLoadMore = async () => {
		setLoading("loadmore");

		await apiCall(
			"get",
			`/api/v1/question?page=${datum?.nextPage}${
				search ? `&search=${search}` : ""
			}`,
			null,
			getLogger
		);
		setPage(1);
		setLoading(false);
	};

	return (
		<>
			<div className="tw-mt-6 tw-grid lg:tw-grid-cols-2 tw-gap-5">
				{currentItems?.map((q, i) => (
					<MainQuestion q={q} key={i} i={i} />
				))}
			</div>
			<div className="tw-mt-4 tw-flex tw-justify-center">
				<LoadMore
					next={page === pageCount && datum?.hasNextPage}
					loading={loading === "loadmore"}
					handleLoadMore={handleLoadMore}
				/>
			</div>
			{pageCount > 1 && (
				<MainPaginate pageCount={pageCount} handlePageClick={handlePageClick} />
			)}
		</>
	);
};

export default Trivia;

export const MainQuestion = ({ q, i, noHover }) => {
	const [hovered, setHovered] = useState(false),
		[loading, setLoading] = useState(false),
		[isDelete, setIsDelete] = useState(""),
		{ returnErrors } = useErrorStore(),
		{ deleteLogger, updateLogger } = useTriviaStore(),
		handleSubmit = async (e, se) => {
			e?.preventDefault();
			setLoading("delete");
			let { response, errArr, errMsg } = await apiCall(
				"delete",
				`/api/v1/question?question=${q?._id}`,
				q
			);
			// console.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				deleteLogger(q);
				setIsDelete(null);
				return;
			}
			setLoading(false);
		},
		[questions, setQuestions] = useState([q]),
		handleInputChangeForMutipleItem = (event, index, field, option) => {
			if (field !== "question") var { value } = event?.target;

			let itemValue = ["answer", "questionType", "question"]?.includes(field)
				? option
				: value;

			// if (field === "price") {
			// 	let parsedValue = parseFloat(itemValue);
			// 	if (Number.isInteger(parsedValue)) {
			// 		parsedValue = parsedValue.toFixed(0);
			// 		itemValue = parsedValue;
			// 	}
			// }

			setQuestions(prevRows => {
				const newRows = [...prevRows];
				newRows[index][field] = itemValue;
				return newRows;
			});
		},
		handleSubmitUpdate = async e => {
			e?.preventDefault();
			let errArr1 = [],
				returnedData = [];
			if (questions?.length === 0) return toast.info("Questions required");
			console.log({ questions });
			for (let e = 0; e < questions.length; e++) {
				let element = questions?.[e],
					newOption = [];
				element.options = [];
				element?.options?.push({
					option: element?.option_a || "",
					isCorrect: element?.answer === "option_a",
					file: element?.option_afile,
					optionType: element?.option_afilequestionType || "text",
				});
				element?.options?.push({
					option: element?.option_b || "",
					isCorrect: element?.answer === "option_b",
					file: element?.option_bfile,
					optionType: element?.option_bfilequestionType || "text",
				});
				if (element?.questionType === "multiple") {
					element?.options?.push({
						option: element?.option_c || "",
						isCorrect: element?.answer === "option_c",
						file: element?.option_cfile,
						optionType: element?.option_cfilequestionType || "text",
					});
					element?.options?.push({
						option: element?.option_d || "",
						isCorrect: element?.answer === "option_d",
						file: element?.option_dfile,
						optionType: element?.option_dfilequestionType || "text",
					});
				}
				if (!element?.question)
					errArr1.push({
						message: `Question is required`,
						path: "question",
					});
				if (!element?.options)
					errArr1.push({
						message: `Question options is required`,
						path: "options",
					});
				// if (element?.questionType)
				// 	if (element?.questionType !== "text") {
				// 		if (!element?.file)
				// 			errArr1.push({
				// 				message: `Question ${e + 1} file is required`,
				// 				path: "file",
				// 			});
				// 	}

				if (!hasUniqueValue(element?.options, "isCorrect", true)) {
					errArr1.push({
						message: `Question options requires a single answer`,
						path: "isCorrect",
					});
				}
				// console.log({ element });
				for (let o = 0; o < element?.options.length; o++) {
					let elementOption = element?.options?.[o];
					if (!elementOption?.option && !elementOption?.file)
						errArr1.push({
							message: `Question Option ${o + 1} is required`,
							path: "option",
						});
					// if (elementOption?.optionType)
					// 	if (elementOption?.optionType !== "text") {
					// 		if (!elementOption?.file)
					// 			errArr1.push({
					// 				message: `Question ${e + 1} Option ${o + 1} file is required`,
					// 				path: "file",
					// 			});
					// 	}
					if (elementOption?.isCorrect) element.correctOptionIndex = o + 1;
					newOption?.push({ ...elementOption });
				}
				returnedData?.push({ ...element, options: newOption });
			}
			console.log({ returnedData });
			if (errArr1?.length > 0) return returnErrors({ error: errArr1 });
			setLoading("update");

			let { response, errArr, errMsg } = await apiCall(
				"put",
				`/api/v1/question?questionID=${q?._id}`,
				returnedData?.[0]
			);
			// console.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				updateLogger(response);
				setIsDelete(null);
				return;
			}
			setLoading(false);
		};

	useEffect(() => {
		let newQ = { ...q },
			list = ["a", "b", "c", "d"];
		for (let n = 0; n < q?.options.length; n++) {
			const element = q?.options[n];
			newQ[`option_${list[n]}`] = element?.option;
			if (element?.isCorrect) newQ["answer"] = `option_${list[n]}`;
		}
		setQuestions([newQ]);
	}, [q]);

	return (
		<>
			<div
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				className="tw-border tw-relative tw-border-scblue tw-rounded-xl tw-p-6 tw-bg-transparent">
				{!noHover && hovered && (
					<div className="tw-absolute tw-top-4 tw-right-4">
						<div className="tw-flex tw-gap-3">
							<FaEdit
								size={20}
								className="tw-cursor-pointer"
								onClick={() => setIsDelete("edit")}
							/>
							<MdDelete
								size={20}
								className="tw-cursor-pointer"
								onClick={() => setIsDelete("delete")}
							/>
						</div>
					</div>
				)}
				<div className="tw-flex gap-2">
					<strong className="tw-text-xl tw-font-bold tw-text-scblue">
						{i + 1}.{" "}
					</strong>
					<strong
						className="tw-text-xl tw-font-bold tw-text-scblue"
						dangerouslySetInnerHTML={createMarkup(q?.question)}
					/>
				</div>
				<div className="tw-mt-6 tw-space-y-6">
					{q?.options?.map((op, idx) => (
						<div className="tw-relative">
							<OptionsComp
								title={
									idx === 0
										? "A"
										: idx === 1
										? "B"
										: idx === 2
										? "C"
										: idx === 3
										? "D"
										: "E"
								}
								value={op?.option}
								isCorrect={op?.isCorrect}
							/>
							{op?.isCorrect && (
								<div className="">
									<div className="tw-flex tw-items-center tw-gap-2">
										<div>
											<small className="tw-text-sm tw-font-normal tw-text-[#002224]">
												Correct Answer
											</small>
										</div>
									</div>
								</div>
							)}
						</div>
					))}
				</div>
			</div>
			{isDelete === "delete" && (
				<ModalContainer handleClose={() => setIsDelete(null)}>
					<form>
						<h3 className="tw-text-2xl tw-font-semibold inter tw-text-scblue tw-flex tw-gap-2">
							Delete{" "}
							<span
								className=""
								dangerouslySetInnerHTML={createMarkup(q?.question)}
							/>{" "}
							Question?
						</h3>
						<div className="tw-mt-10 tw-flex tw-items-center tw-justify-center">
							<div className="tw-grid tw-grid-cols-2 tw-gap-2">
								<PrimaryBtn
									width={"tw-w-full"}
									bg={"#1C466C"}
									text={"Delete"}
									color={"white"}
									onClick={handleSubmit}
									loading={loading === "delete"}
								/>
								<TabBtn
									css={"tw-border-0.5 tw-border-[#0A1C2DB2]"}
									text={"Cancel"}
									color={"#1C466C"}
									onClick={() => setIsDelete(null)}
									bg={"white"}
								/>
							</div>
						</div>
					</form>
				</ModalContainer>
			)}
			{isDelete === "edit" && (
				<ModalContainer height={true} handleClose={() => setIsDelete(null)}>
					<form>
						<h3 className="tw-text-2xl tw-font-semibold inter tw-text-scblue tw-flex tw-gap-2">
							Edit{" "}
							<span
								className=""
								dangerouslySetInnerHTML={createMarkup(q?.question)}
							/>{" "}
							Question
						</h3>
						{questions?.map((question, i) => (
							<Fragment key={i}>
								<RichTextEditor
									label={`Question`}
									value={question?.question}
									setState={event =>
										handleInputChangeForMutipleItem(event, i, "question", event)
									}
								/>
								<div>
									<h4 className="tw-text-base tw-font-bold tw-text-primary">
										Options
									</h4>
									<div className="tw-mt-4">
										<h6 className="tw-text-base tw-font-medium tw-text-newblue">
											Type
										</h6>
										<div className="tw-flex tw-gap-3 ">
											<div className="tw-h-12 tw-w-44 tw-bg-[#E9EEF2] tw-border-0.5 tw-border-[#FFFFFF33] tw-rounded tw-flex tw-items-center tw-px-3 tw-justify-between">
												<div>
													<small className="tw-text-newblue tw-font-normal tw-text-sm">
														Multiple Answers
													</small>
												</div>
												<input
													type="radio"
													name={`questionType${i}`}
													className="tw-size-4 tw-mt-1"
													id=""
													value={question?.questionType}
													onChange={event =>
														handleInputChangeForMutipleItem(
															event,
															i,
															"questionType",
															"multiple"
														)
													}
													checked={question?.questionType === "multiple"}
												/>
											</div>
											<div className="tw-h-12 tw-w-44 tw-bg-[#E9EEF2] tw-border-0.5 tw-border-[#FFFFFF33] tw-rounded tw-flex tw-items-center tw-px-3 tw-justify-between">
												<div>
													<small className="tw-text-newblue tw-font-normal tw-text-sm">
														Single Answer
													</small>
												</div>
												<input
													type="radio"
													name={`questionType${i}`}
													className="tw-size-4 tw-mt-1"
													id=""
													value={question?.questionType}
													onChange={event =>
														handleInputChangeForMutipleItem(
															event,
															i,
															"questionType",
															"single"
														)
													}
													checked={question?.questionType === "single"}
												/>
											</div>
										</div>
										<div className="tw-mt-5 tw-w-full tw-space-y-4">
											<OptionsInput
												option={"option_a"}
												question={question}
												index={i}
												handleInputChangeForMutipleItem={
													handleInputChangeForMutipleItem
												}
												title="A"
											/>
											<OptionsInput
												option={"option_b"}
												question={question}
												index={i}
												handleInputChangeForMutipleItem={
													handleInputChangeForMutipleItem
												}
												title="B"
											/>
											{question?.questionType === "multiple" && (
												<>
													<OptionsInput
														option={"option_c"}
														question={question}
														index={i}
														handleInputChangeForMutipleItem={
															handleInputChangeForMutipleItem
														}
														title="C"
													/>
													<OptionsInput
														option={"option_d"}
														question={question}
														index={i}
														handleInputChangeForMutipleItem={
															handleInputChangeForMutipleItem
														}
														title="D"
													/>
												</>
											)}
										</div>
									</div>
								</div>
							</Fragment>
						))}
						<div className="tw-mt-10 tw-flex tw-items-center tw-justify-center">
							<div className="tw-grid tw-grid-cols-2 tw-gap-2">
								<PrimaryBtn
									width={"tw-w-full"}
									bg={"#1C466C"}
									text={"Update"}
									color={"white"}
									onClick={handleSubmitUpdate}
									loading={loading === "update"}
								/>
								<TabBtn
									css={"tw-border-0.5 tw-border-[#0A1C2DB2]"}
									text={"Cancel"}
									color={"#1C466C"}
									onClick={() => setIsDelete(null)}
									bg={"white"}
								/>
							</div>
						</div>
					</form>
				</ModalContainer>
			)}
		</>
	);
};
