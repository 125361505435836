import React, { useState, useRef } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Upload from "../assets/upload.svg";

const TextInput = ({
	label,
	onChange,
	type,
	value,
	name,
	width,
	height,
	...restProps
}) => {
	return (
		<div>
			<div>
				<strong className="tw-text-base tw-font-medium tw-text-scgreen">
					{label}
				</strong>
			</div>
			{type === "textarea" ? (
				<textarea
					value={value}
					onChange={onChange}
					name={name}
					className={`${width || "tw-w-full"} ${
						height || "tw-h-12"
					} tw-border-0.5 tw-border-[#00222480] tw-pl-6 tw-mt-2 tw-rounded-xl tw-text-base tw-font-normal tw-text-scgreen`}
					{...restProps}
					style={{ resize: "none", height: "10rem" }}
				/>
			) : (
				<input
					type={type || "text"}
					value={value}
					onChange={onChange}
					name={name}
					className={`${width || "tw-w-full"} ${
						height || "tw-h-12"
					} tw-border-0.5 tw-border-[#00222480] tw-pl-6 tw-mt-2 tw-rounded-xl tw-text-base tw-font-normal tw-text-scgreen`}
					{...restProps}
				/>
			)}
		</div>
	);
};

export const PasswordInput = ({
	label,
	onChange,
	type,
	value,
	name,
	width,
	height,
	...restProps
}) => {
	const [show, setShow] = useState();
	return (
		<div>
			<div>
				<strong className="tw-text-base tw-font-medium tw-text-scgreen">
					{label}
				</strong>
			</div>
			<div
				className={`${width || "tw-w-full"} ${
					height || "tw-h-12"
				} tw-relative`}>
				<input
					type={show ? "text" : "password"}
					value={value}
					name={name}
					onChange={onChange}
					className="tw-h-full tw-pl-6 tw-w-full tw-border-0.5 tw-border-[#00222480] tw-mt-2 tw-rounded-xl tw-text-base tw-font-normal tw-text-scgreen"
					{...restProps}
				/>
				<div
					onClick={() => setShow(!show)}
					className="tw-absolute tw-top-6 tw-right-4 tw-cursor-pointer">
					{show ? <BsEyeSlash size={15} /> : <BsEye size={15} />}
				</div>
			</div>
		</div>
	);
};

export const RichTextEditor = ({ label, value, name, setState }) => {
	return (
		<div>
			<div>
				<strong className="tw-text-base tw-capitalize tw-font-normal tw-text-black">
					{label}
				</strong>
			</div>
			<div className="tw-w-full tw-mt-4">
				<ReactQuill theme="snow" value={value} onChange={e => setState(e)} />
			</div>
		</div>
	);
};

export const SelectInput = ({
	label,
	name,
	options,
	onChange,
	value,
	...restProps
}) => {
	return (
		<div>
			<div>
				<div>
					<small className="tw-text-base tw-font-medium tw-text-scgreen">
						{label}
					</small>
				</div>
				<select
					onChange={onChange}
					value={value}
					name={name}
					className={`${restProps?.width || "tw-w-full"} ${
						restProps?.height || "tw-h-12"
					} tw-border-0.5 tw-border-[#00222480] tw-pl-6 tw-mt-2 tw-rounded-xl tw-text-base tw-font-normal tw-text-scgreen`}
					{...restProps}
					id="">
					<option value="" selected>
						Choose Value
					</option>
					{options?.map((it, i) => (
						<option
							key={i}
							value={
								it?._id || it?.code || it?.name || it?.title || it?.value || it
							}>
							{name === "stateOfResidence" ? (
								<>
									{it?.name}: {it?.capital}
								</>
							) : (
								it?.title || it?.value || it?.name || it
							)}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export const ImageInput = ({ name, label, onChange, state }) => {
	const ref = useRef();
	const handleClick = () => {
		ref.current?.click();
	};

	return (
		<div>
			<div>
				{" "}
				<small className="tw-text-base tw-font-medium satoshi tw-text-secondary">
					{label}
				</small>
			</div>
			<div
				onClick={handleClick}
				className="tw-h-28 tw-cursor-pointer  tw-border-[#7B87944D] tw-border tw-w-full tw-flex tw-justify-center tw-items-center tw-bg-white tw-rounded-md">
				<div className="">
					{state ? (
						<>
							{name === "video" ? (
								<video
									src={state?.url || URL.createObjectURL(state)}
									alt=""
									className="tw-mx-auto tw-max-h-24"
									muted
									autoPlay
									controls
								/>
							) : (
								<img
									src={state?.url || URL.createObjectURL(state)}
									alt=""
									className="tw-mx-auto tw-max-h-24"
								/>
							)}
						</>
					) : (
						<>
							<img src={Upload} alt="" className="tw-mx-auto" />
							<small className="tw-text-xs tw-text-[#275A7F] tw-font-light satoshi tw-text-center">
								Click here to upload or Drop files here
							</small>
						</>
					)}
					<input
						type="file"
						name={name}
						onChange={onChange}
						ref={ref}
						accept={name === "video" ? ".mp4, .mkv" : ".png, .jpg, .png"}
						id=""
						className="tw-hidden"
					/>
				</div>
			</div>
		</div>
	);
};

export default TextInput;
