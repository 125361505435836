import React, { useState } from "react";
import moment from "moment";
import MainContainer from "../../components/maincontainer";
import Header from "../../components/header";
import { TabBtn } from "../../components/buttons";
import Avatar from "../../assets/tableavatar.svg";
import usePlanStore from "../../data/Stores/planstore";
import { useSubscriptionStore } from "../../data/Stores/loggerStore";
import { useEffect } from "react";
import { apiCall } from "../../data/Stores/AuthStore";
import { LoadMore, MainPaginate } from "../../components/Pagination";

const Subscriptions = () => {
	return (
		<div>
			<MainContainer>
				<Header text={"Subscriptions"} />
				<MainSection />
			</MainContainer>
		</div>
	);
};

const MainSection = () => {
	const [tab, setTab] = useState("all");
	const { data } = usePlanStore();

	return (
		<section className="tw-w-full tw-bg-white tw-p-8 tw-mt-10">
			<div className="tw-flex tw-gap-3">
				<TabBtn
					onClick={() => setTab("all")}
					text={"all"}
					css={
						tab === "all"
							? "tw-border-none"
							: "tw-border-0.5 tw-border-[#0A1C2DB2]"
					}
					bg={tab === "all" ? "#1C466C" : "white"}
					color={tab === "all" ? "white" : "#1C466C"}
				/>
				{data?.docs?.map((section, i) => (
					<TabBtn
						key={i}
						onClick={() => setTab(section?.title)}
						text={section?.title}
						css={
							tab === section?.title
								? "tw-border-none"
								: "tw-border-0.5 tw-border-[#0A1C2DB2]"
						}
						bg={tab === section?.title ? "#1C466C" : "white"}
						color={tab === section?.title ? "white" : "#1C466C"}
					/>
				))}
			</div>
			<div className="tw-mt-10">
				<AllSection
					plans={data}
					plan={
						tab && tab !== "all"
							? data?.docs?.find(it => it?.title === tab)?._id
							: ""
					}
				/>
			</div>
		</section>
	);
};

const AllSection = ({ plan }) => {
	const headers = [
			"SN.",
			"Name",
			"plan",
			"start date",
			"end date",
			"no of trivia played",
		],
		tableData = [
			{
				name: "Kolawonle Henry",
				plan: "Silver",
				start_date: "14 May 2014",
				end_date: "14 May 2014",
				trivia: "6",
			},
			{
				name: "Kolawonle Henry",
				plan: "Silver",
				start_date: "14 May 2014",
				end_date: "14 May 2014",
				trivia: "6",
			},
			{
				name: "Kolawonle Henry",
				plan: "Silver",
				start_date: "14 May 2014",
				end_date: "14 May 2014",
				trivia: "6",
			},
			{
				name: "Kolawonle Henry",
				plan: "Silver",
				start_date: "14 May 2014",
				end_date: "14 May 2014",
				trivia: "6",
			},
			{
				name: "Kolawonle Henry",
				plan: "Silver",
				start_date: "14 May 2014",
				end_date: "14 May 2014",
				trivia: "6",
			},
			{
				name: "Kolawonle Henry",
				plan: "Silver",
				start_date: "14 May 2014",
				end_date: "14 May 2014",
				trivia: "6",
			},
		];

	let [datum, setDatum] = useState({ docs: tableData }),
		[loading, setLoading] = useState(null),
		{ isFound, mainSearch, data: mainData, getLogger } = useSubscriptionStore(),
		[search] = useState("");

	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(mainData);
	}, [mainData, isFound, mainSearch]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/subscription${plan ? `?plan=${plan}` : ""}`,
			null,
			getLogger
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [plan]);

	const [range] = useState(10),
		[page, setPage] = useState(1),
		[itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	let handleLoadMore = async () => {
		setLoading("loadmore");

		await apiCall(
			"get",
			`/api/v1/subscription?limit=${datum?.limit * datum?.nextPage}${
				search ? `&search=${search}` : ""
			}${plan ? `&plan=${plan}` : ""}`,
			null,
			getLogger
		);
		setLoading(false);
	};

	return (
		<div>
			<div className="tw-w-full tw-border-0.5 tw-border-[#00555A80] tw-rounded-xl tw-p-10">
				<div className="tw-hidden lg:tw-block">
					<table className="tw-w-full">
						<thead>
							{headers?.map((it, i) => (
								<th
									className={`tw-text-sm tw-font-medium tw-capitalize satoshi tw-text-[#06094F] ${
										i === 0 ? "tw-w-8" : "tw-w-20"
									}`}>
									{it}
								</th>
							))}
						</thead>
						<tbody>
							{currentItems?.map((item, i) => (
								<tr
									className={`tw-border-b tw-border-b-[#CDDCE0] tw-h-16`}
									key={i}>
									<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
										{i + 1}
									</td>
									<td>
										<div className="tw-flex tw-gap-2 tw-items-center">
											<img src={Avatar} alt="" className="" />
											<div>
												<small className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
													{item?.user?.firstName} {item?.user?.lastName}
												</small>
											</div>
										</div>
									</td>
									<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa tw-capitalize">
										{item?.plan?.title}
									</td>
									<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
										{moment(item?.subscribeOn).format("Do MMMM, YYYY")}
									</td>
									<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
										{moment(item?.subscribeEnd).format("Do MMMM, YYYY")}
									</td>
									<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
										{item?.triviaTotal}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="tw-block lg:tw-hidden">
					<div className="tw-space-y-4">
						{currentItems?.map((it, i) => (
							<div
								key={i}
								className={`bordered tw-h-20 tw-px-4 tw-w-full tw-flex tw-justify-between tw-items-center`}>
								<div>
									<img src={Avatar} alt="" className="" />
									<div className="tw-mt-2">
										<small className="tw-text-sm tw-font-normal tw-text-plain nexa">
											{it?.user?.firstName} {it?.user?.lastName}
										</small>
									</div>
								</div>
								<div>
									<div className="tw-mt-22">
										{" "}
										<small className="tw-text-sm tw-font-normal tw-text-plain nexa">
											{it?.plan?.title}
										</small>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="tw-mt-4 tw-flex tw-justify-center">
					<LoadMore
						next={page === pageCount && datum?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
		</div>
	);
};

export default Subscriptions;
