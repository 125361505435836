import React, { useState } from "react";
import ModalContainer from "./modalcontainer";
import TextInput, { ImageInput, RichTextEditor, SelectInput } from "../inputs";
import PrimaryBtn from "../buttons";
import useCategoryStore from "../../data/Stores/categorystore";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";
import { apiCall } from "../../data/Stores/AuthStore";
import useListingStore from "../../data/Stores/listingStore";

const AddNewPropertyModal = ({ handleClose, item }) => {
	const { data } = useCategoryStore();

	const init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		{ addListing, updateListing } = useListingStore();
	let handleSubmit = async e => {
		e?.preventDefault();
		let err2 = [];
		if (!item?._id) {
			if (!state?.title) err2?.push("Property Title is required");
			if (!state?.linkedURL) err2?.push("Property URL is required");
			if (!state?.category) err2?.push("Property category is required");
			if (!state?.content) err2?.push("Property description is required");
			if (!state?.image) err2?.push("Property image is required");
		}
		if (err2?.length > 0) return err2?.forEach(it => toast.info(it));
		setLoading(true);
		let newState = { ...state };
		if (state?.image)
			newState = {
				...newState,
				intendedFile: state?.image,
				intendedFileName: "image",
			};
		let { response, errArr, errMsg } = await apiCall(
			item?._id ? "put" : "post",
			`/api/v1/listing${item?._id ? `?listing=${item?._id}` : ""}`,
			newState,
			null,
			state?.image ? { "Content-Type": "multipart/form-data" } : null
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			setState({});
			if (item?._id) {
				updateListing({
					...response,
					adder: data?.docs?.find(it => it?._id === state?.category)?.title,
				});
			} else
				addListing({
					...response,
					adder: data?.docs?.find(it => it?._id === state?.category)?.title,
				});
			handleClose();
			return;
		}
		setLoading(false);
	};

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes(e?.target?.name))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			// setLogo(file);

			setState(prevRows => {
				let newRows = { ...prevRows };
				newRows[e?.target?.name || "image"] = file;
				return newRows;
			});
		}
	};

	return (
		<div>
			<ModalContainer height={true} handleClose={handleClose}>
				<form action="">
					<h3 className="tw-text-2xl tw-font-semibold inter tw-text-scblue">
						{item?._id ? `Update ${item?.title || ""}` : `Add New`} Property
					</h3>
					<div className="tw-mt-5 tw-space-y-4">
						<TextInput
							label={"Name of Property*"}
							value={state?.title || item?.title}
							name="title"
							onChange={textChange}
						/>
						<TextInput
							label={"Property URL"}
							value={state?.linkedURL || item?.linkedURL}
							name="linkedURL"
							onChange={textChange}
							type={"url"}
						/>
						<TextInput
							label={"Property Location"}
							value={state?.location || item?.location}
							name="location"
							onChange={textChange}
						/>
						<RichTextEditor
							label={"Description*"}
							value={state?.content || item?.content}
							setState={e => setState({ ...state, content: e })}
						/>
						<ImageInput
							label={"Property Images*"}
							name={"image"}
							state={state?.image || item?.image}
							onChange={handleChangeImage}
						/>
						<TextInput
							label={"Number of Bedroom"}
							value={state?.noOfBedroom || item?.noOfBedroom}
							name="noOfBedroom"
							onChange={textChange}
							type="number"
							min={0}
						/>
						<TextInput
							min={0}
							label={"Number of Bathroom"}
							value={state?.noOfBathRoom || item?.noOfBathRoom}
							name="noOfBathRoom"
							onChange={textChange}
							type="number"
						/>
						<TextInput
							label={"Number of Kitchen"}
							value={state?.noOfKitchen || item?.noOfKitchen}
							min={0}
							name="noOfKitchen"
							onChange={textChange}
							type="number"
						/>
						<TextInput
							min={0}
							label={"Number of Parking"}
							value={state?.noOfParking || item?.noOfParking}
							name="noOfParking"
							onChange={textChange}
							type="number"
						/>
						<SelectInput
							label={"Property Category*"}
							value={state?.category || item?.category}
							name="category"
							onChange={textChange}
							options={data?.docs}
						/>
					</div>
					<div className="tw-mt-10">
						<PrimaryBtn
							width={"tw-w-full"}
							bg={"#1C466C"}
							text={item?._id ? "Update Property" : "Publish Property"}
							color={"white"}
							onClick={handleSubmit}
							loading={loading}
						/>
					</div>
				</form>
			</ModalContainer>
		</div>
	);
};

export default AddNewPropertyModal;

export const AddNewCategoryModal = ({ handleClose }) => {
	const init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		{ addCategory } = useCategoryStore();
	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.title || !state?.homePageTitle || !state?.propertyPageTitle)
			return toast.info("Category titles' are required");
		setLoading(true);
		let newState = { ...state };
		if (state?.image)
			newState = {
				...newState,
				intendedFile: state?.image,
				intendedFileName: "image",
			};
		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/category`,
			newState,
			null,
			state?.image ? { "Content-Type": "multipart/form-data" } : null
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			setState({});
			addCategory(response);
			handleClose();
			return;
		}
		setLoading(false);
	};

	return (
		<div>
			<ModalContainer height={true} handleClose={handleClose}>
				<form action="">
					<h3 className="tw-text-2xl tw-font-semibold inter tw-text-scblue">
						Add New Category
					</h3>
					<div className="tw-mt-5 tw-space-y-4">
						<TextInput
							label={"Title"}
							value={state?.title}
							name="title"
							onChange={textChange}
						/>
						<TextInput
							label={"Category Home Page Title"}
							value={state?.homePageTitle}
							name="homePageTitle"
							onChange={textChange}
						/>
						<TextInput
							label={"Category Property Page Title"}
							value={state?.propertyPageTitle}
							name="propertyPageTitle"
							onChange={textChange}
						/>
						<RichTextEditor
							label={"Description"}
							value={state?.description}
							setState={e => setState({ ...state, description: e })}
						/>
					</div>
					<div className="tw-mt-10">
						<PrimaryBtn
							onClick={handleSubmit}
							width={"tw-w-full"}
							bg={"#1C466C"}
							text={"Publish Category"}
							color={"white"}
							loading={loading}
						/>
					</div>
				</form>
			</ModalContainer>
		</div>
	);
};
