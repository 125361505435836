import React from "react";
import Close from "../../assets/close.svg";

const ModalContainer = ({ handleClose, children, height }) => {
  return (
    <div>
      <div
        onClick={(e) => e.target === e.currentTarget && handleClose()}
        className="tw-fixed tw-inset-0 tw-z-[1000] tw-w-full tw-flex tw-justify-center tw-items-center tw-min-h-screen tw-bg-primary tw-bg-opacity-50 tw-backdrop-blur-sm"
      >
        <div
          className={`tw-w-full tw-max-w-md  tw-bg-white tw-rounded-lg tw-shadow-sm tw-transition-all tw-ease-in-out tw-duration-700 ${
            height && "tw-h-3/4"
          } tw-overflow-y-scroll noScroll p-4`}
        >
          <div className="tw-flex tw-justify-end">
            <img
              src={Close}
              alt=""
              className="tw-cursor-pointer"
              onClick={handleClose}
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalContainer;
