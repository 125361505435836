import axios from "axios";
import { SetAuthToken } from "./Config";
import useAuthStore, { TOKEN, apiCall } from "./Stores/AuthStore";
import { toast } from "react-toastify";
import useErrorStore from "./Stores/errorstore";
import usePlanStore from "./Stores/planstore";
import useCategoryStore from "./Stores/categorystore";
import useSettingStore from "./Stores/settingsstore";
import useUserStore from "./Stores/userstore";
import useListingStore from "./Stores/listingStore";

const useGenFetcher = () => {
	let { getErrorText, clearErrors } = useErrorStore(),
		{ getUser, getUserFail, getUserLoading } = useAuthStore(),
		{ getPlan } = usePlanStore(),
		{ getSetting, getLeaderboard } = useSettingStore(),
		{ getCategory } = useCategoryStore(),
		{ getListing, getDashboard } = useListingStore(),
		{ getUsers } = useUserStore(),
		loadUser = async () => {
			let token = localStorage.getItem(TOKEN);
			if (token) SetAuthToken(token);

			getUserLoading();
			clearErrors();
			try {
				let res = await axios.get(`/api/v1/user`);
				// console.log({ res: res?.data });
				if (res?.data?.data?.isAdmin) {
					getUser(res.data);
					apiCall("get", "/api/v1/plan", null, getPlan);
					apiCall("get", "/api/v1/settings", null, getSetting);
					apiCall("get", "/api/v1/category", null, getCategory);
					apiCall("get", "/api/v1/listing", null, getListing);
					apiCall("get", "/api/v1/listing/dashboard", null, getDashboard);
					apiCall(
						"get",
						"/api/v1/user/manage-users?privilege=user",
						null,
						getUsers
					);
					apiCall("get", "/api/v1/question/leaderboard", null, getLeaderboard);
				} else {
					getUserFail();
					getErrorText("Unauthorized User, Access denied");
				}
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				getUserFail();
				getErrorText(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						""
				);
			}
		};
	return { loadUser };
};

export default useGenFetcher;
