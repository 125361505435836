import { createElement } from "react";
import useAuthStore from "./data/Stores/AuthStore";
import { useParams, useNavigate } from "react-router-dom";

const GeneratePage = (pageName, folder) => {
	const component = () => require(`./${folder}/${pageName}`).default;
	let navigate = useNavigate();
	try {
		return createElement(component());
	} catch (e) {
		console.log(e);
		navigate("/");
	}
};

const PageRender = () => {
	const { isAuth } = useAuthStore(),
		{ step, id, page } = useParams(),
		escape2 = [];

	// if (!auth) {
	//   navigate("/");
	// }
	//   useEffect(() => {
	//     if (
	//       isAuth &&
	//       (location.pathname === "/login" ||
	//         location.pathname.startsWith("/login/"))
	//     ) {
	//       navigate("/");
	//     }
	//   }, [navigate, isAuth, location]);

	let pageName = "";
	if (step) {
		pageName = `${page}/${id}/${"[id]"}`;
	} else if (id) {
		if (page === "talent-register" && escape2.includes(id)) {
			pageName = `${page}/${id}`;
		} else {
			pageName = `${page}/${"[id]"}`;
		}
	} else {
		pageName = `${page}`;
	}
	return GeneratePage(pageName, isAuth ? "pages" : "screens");
};

export default PageRender;
