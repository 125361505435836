import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { DeleteData, EditData } from "./AuthStore";

const ListingStore = set => ({
	data: null,
	status: "",
	getSearchListing: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchListing"
		);
	},
	getSearch: payload => {
		set({ data: payload?.data || payload }, false, "getSearch");
	},
	resetListingSearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getListing: payload => {
		let data = payload?.data || payload,
			dd = {};
		if (payload?.adder) {
			dd = {
				[payload.adder]: data,
			};
		} else {
			dd = { data };
		}
		set(dd, false, "getListing");
	},
	getDashboard: payload => {
		set({ dashboard: payload?.data || payload }, false, "getDashboard");
	},
	getAllListing: payload => {
		set({ allData: payload?.data || payload }, false, "getAllListing");
	},
	addListing: payload => {
		let data = payload?.data || payload,
			dd = {};

		let updateFunc = state => {
			if (payload?.adder) {
				dd = {
					data: {
						...state?.data,
						docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
						totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
						docsTotal: state?.data?.docsTotal ? 1 + state?.data?.docsTotal : 1,
					},
					[payload.adder]: {
						...state?.[payload.adder],
						docs: state?.[payload.adder]?.docs
							? [data, ...state?.[payload.adder]?.docs]
							: [data],
						totalDocs: state?.[payload.adder]?.totalDocs
							? 1 + state?.data?.totalDocs
							: 1,
						docsTotal: state?.[payload.adder]?.docsTotal
							? 1 + state?.[payload.adder]?.docsTotal
							: 1,
					},
				};
			} else {
				dd = {
					data: {
						...state?.data,
						docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
						totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
						docsTotal: state?.data?.docsTotal ? 1 + state?.data?.docsTotal : 1,
					},
				};
			}
			return { ...dd, status: "added" };
		};

		set(state => updateFunc(state), false, "addListing");
	},
	deleteListing: payload => {
		let data = payload?.data || payload,
			dd = {};

		let updateFunc = state => {
			if (payload?.adder) {
				dd = {
					data: {
						...state?.data,
						docs: DeleteData(state?.data?.docs, data),
						totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
						docsTotal: state?.data?.docsTotal ? state?.data?.docsTotal - 1 : 0,
					},
					[payload.adder]: {
						...state?.[payload.adder],
						docs: DeleteData(state?.[payload.adder]?.docs, data),
						totalDocs: state?.[payload.adder]?.totalDocs
							? state?.[payload.adder]?.totalDocs - 1
							: 0,
						docsTotal: state?.[payload.adder]?.docsTotal
							? state?.[payload.adder]?.docsTotal - 1
							: 0,
					},
				};
			} else {
				dd = {
					data: {
						...state?.data,
						docs: DeleteData(state?.data?.docs, data),
						totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
						docsTotal: state?.data?.docsTotal ? state?.data?.docsTotal - 1 : 0,
					},
				};
			}
			return { ...dd, status: "deleted" };
		};
		set(state => updateFunc(state), false, "deleteListing");
	},
	updateListing: payload => {
		let data = payload?.data || payload,
			dd = {};

		let updateFunc = state => {
			if (payload?.adder) {
				dd = {
					data: {
						...state?.data,
						docs: EditData(state?.data?.docs, data),
					},
					[payload.adder]: {
						...state?.[payload.adder],
						docs: EditData(state?.[payload.adder]?.docs, data),
					},
				};
			} else {
				dd = {
					data: {
						...state?.data,
						docs: EditData(state?.data?.docs, data),
					},
				};
			}
			return { ...dd, status: "updated" };
		};

		set(state => updateFunc(state), false, "editListing");
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutListing: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null });
	},
});

const useListingStore = create(
	devtools(
		persist(ListingStore, {
			name: "listing",
		})
	)
);

export default useListingStore;
