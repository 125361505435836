import React, { useState, useEffect, useContext } from "react";
import MainContainer from "../../components/maincontainer";
import Header from "../../components/header";
import PrimaryBtn, { TabBtn } from "../../components/buttons";
import { FaAngleDown } from "react-icons/fa6";
import TextInput, {
	RichTextEditor,
	SelectInput,
} from "../../components/inputs";
import usePlanStore from "../../data/Stores/planstore";
import { GlobalState } from "../../data/Context";
import { apiCall } from "../../data/Stores/AuthStore";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";
import useSettingStore from "../../data/Stores/settingsstore";
import useCategoryStore from "../../data/Stores/categorystore";
import { AddNewCategoryModal } from "../../components/modals/add-new-property-modal";

const Contents = () => {
	return (
		<div>
			<MainContainer>
				<Header text={"Contents"} />
				<MainSection />
			</MainContainer>
		</div>
	);
};
const MainSection = () => {
	const [tab, setTab] = useState("home"),
		{ getSetting } = useSettingStore();

	useEffect(() => {
		apiCall("get", "/api/v1/settings", null, getSetting);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className="tw-w-full tw-px-8 tw-mt-10">
			<div className="tw-flex tw-gap-3">
				<TabBtn
					onClick={() => setTab("home")}
					text={"home page"}
					css={
						tab === "home"
							? "tw-border-none"
							: "tw-border-0.5 tw-border-[#0A1C2DB2]"
					}
					bg={tab === "home" ? "#1C466C" : "white"}
					color={tab === "home" ? "white" : "#1C466C"}
				/>
				<TabBtn
					onClick={() => setTab("policy")}
					text={"policy"}
					css={
						tab === "policy"
							? "tw-border-none"
							: "tw-border-0.5 tw-border-[#0A1C2DB2]"
					}
					bg={tab === "policy" ? "#1C466C" : "white"}
					color={tab === "policy" ? "white" : "#1C466C"}
				/>
				<TabBtn
					onClick={() => setTab("pricing")}
					text={"pricing"}
					css={
						tab === "pricing"
							? "tw-border-none"
							: "tw-border-0.5 tw-border-[#0A1C2DB2]"
					}
					bg={tab === "pricing" ? "#1C466C" : "white"}
					color={tab === "pricing" ? "white" : "#1C466C"}
				/>
				<TabBtn
					onClick={() => setTab("terms")}
					text={"terms & conditions"}
					css={
						tab === "terms"
							? "tw-border-none"
							: "tw-border-0.5 tw-border-[#0A1C2DB2]"
					}
					bg={tab === "terms" ? "#1C466C" : "white"}
					color={tab === "terms" ? "white" : "#1C466C"}
				/>
				<TabBtn
					onClick={() => setTab("knowledge")}
					text={"knowledge check & contact"}
					css={
						tab === "knowledge"
							? "tw-border-none"
							: "tw-border-0.5 tw-border-[#0A1C2DB2]"
					}
					bg={tab === "knowledge" ? "#1C466C" : "white"}
					color={tab === "knowledge" ? "white" : "#1C466C"}
				/>
				<TabBtn
					onClick={() => setTab("category")}
					text={"Category"}
					css={
						tab === "category"
							? "tw-border-none"
							: "tw-border-0.5 tw-border-[#0A1C2DB2]"
					}
					bg={tab === "category" ? "#1C466C" : "white"}
					color={tab === "category" ? "white" : "#1C466C"}
				/>
				<TabBtn
					onClick={() => setTab("settings")}
					text={"Settings"}
					css={
						tab === "settings"
							? "tw-border-none"
							: "tw-border-0.5 tw-border-[#0A1C2DB2]"
					}
					bg={tab === "settings" ? "#1C466C" : "white"}
					color={tab === "settings" ? "white" : "#1C466C"}
				/>
			</div>
			<div className="tw-mt-10">
				{tab === "home" && <HomePage />}
				{tab === "policy" && <Terms type={"privacyPolicy"} />}
				{tab === "pricing" && <Pricing />}
				{tab === "category" && <Category />}
				{tab === "terms" && <Terms type={"termsAndConditions"} />}
				{tab === "knowledge" && <Terms type={"knowledgeCheck"} />}
				{tab === "settings" && <Settings />}
			</div>
		</section>
	);
};

const HomePage = () => {
	const [active, setActive] = useState(null);
	const sections = [
		{
			name: "Hero Section",
			children: [
				{
					label: "Hero Header",
					name: "hero_header",
					value: "hero_header",
					type: "homeTitle",
				},
				{
					label: "Hero Subtitle (Header)",
					name: "hero_subtitle",
					value: "hero_subtitle",
					type: "homeSubTitle",
				},
				{
					label: "Hero Button",
					name: "hero_button",
					value: "hero_button",
					type: "homeButton",
				},
			],
		},
		{
			name: "Section 2",
			children: [
				{
					label: "Header",
					name: "header",
					value: "header",
					type: "section1Title",
				},
				{
					label: "Subtitle (Body Text)",
					name: "hero_subtitle",
					value: "hero_subtitle",
					type: "section1SubTitle",
				},
				{
					label: "Header (Mini Header Text)",
					name: "hero_subtitle",
					value: "hero_subtitle",
					type: "section1SubTitle3",
				},
				{
					label: "Subtitle (Mini Body Text)",
					name: "hero_subtitle",
					value: "hero_subtitle",
					type: "section1SubTitle2",
				},
			],
		},
		{
			name: "Section 3",
			children: [
				{
					label: "Header",
					name: "header",
					value: "header",
					type: "section2Title",
				},
				{
					label: "Subtitle (Body Text)",
					name: "hero_subtitle",
					value: "hero_subtitle",
					type: "section2SubTitle",
				},
			],
		},
		{
			name: "Register",
			children: [
				{
					label: "Header",
					name: "header",
					value: "header",
					type: "registerTitle",
				},
				{
					label: "Register (Body Text)",
					name: "hero_subtitle",
					value: "hero_subtitle",
					type: "registerSubTitle",
				},
			],
		},
		{
			name: "Login",
			children: [
				{
					label: "Header",
					name: "header",
					value: "header",
					type: "loginTitle",
				},
				{
					label: "Login (Body Text)",
					name: "hero_subtitle",
					value: "hero_subtitle",
					type: "loginSubTitle",
				},
			],
		},
		{
			name: "Footer",
			children: [
				{
					label: "Header",
					name: "header",
					value: "header",
					type: "footerTitle",
				},
				{
					label: "Footer (Body Text)",
					name: "hero_subtitle",
					value: "hero_subtitle",
					type: "footerSubTitle",
				},
			],
		},
	];

	return (
		<section className="">
			<div className="tw-w-1/2 tw-space-y-4">
				{sections?.map((section, i) => (
					<div key={i} className="">
						<div
							onClick={() => setActive(active === i ? null : i)}
							className="tw-h-14 tw-w-full tw-cursor-pointer tw-rounded-xl tw-border-0.5 tw-border-[#00555A4D] tw-px-4 tw-flex tw-justify-between tw-items-center">
							<div>
								<small className="tw-text-base tw-font-normal tw-text-black">
									{section?.name}
								</small>
							</div>
							<FaAngleDown />
						</div>
						{active === i && (
							<div className="tw-space-y-4 tw-mt-4">
								{section?.children?.map(it => (
									<Terms type={it?.type} title={it?.label} textarea />
								))}
							</div>
						)}
					</div>
				))}
			</div>
		</section>
	);
};

const Pricing = () => {
	const [active, setActive] = useState(null);
	// const sections = [
	// 	{
	// 		name: "Bronze",
	// 		children: [
	// 			{
	// 				label: "per day",
	// 				name: "hero_header",
	// 				value: "hero_header",
	// 			},
	// 			{
	// 				label: "per name",
	// 				name: "hero_subtitle",
	// 				value: "hero_subtitle",
	// 			},
	// 			{
	// 				label: "access hour",
	// 				name: "hero_button",
	// 				value: "hero_button",
	// 			},
	// 			{
	// 				label: "number of access",
	// 				name: "hero_header",
	// 				value: "hero_header",
	// 			},
	// 			{
	// 				label: "feature",
	// 				name: "hero_subtitle",
	// 				value: "hero_subtitle",
	// 			},
	// 			{
	// 				label: "No. of coins",
	// 				name: "hero_button",
	// 				value: "hero_button",
	// 			},
	// 			{
	// 				label: "cash prizes",
	// 				name: "hero_button",
	// 				value: "hero_button",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		name: "Silver",
	// 		children: [
	// 			{
	// 				label: "per day",
	// 				name: "hero_header",
	// 				value: "hero_header",
	// 			},
	// 			{
	// 				label: "per name",
	// 				name: "hero_subtitle",
	// 				value: "hero_subtitle",
	// 			},
	// 			{
	// 				label: "access hour",
	// 				name: "hero_button",
	// 				value: "hero_button",
	// 			},
	// 			{
	// 				label: "number of access",
	// 				name: "hero_header",
	// 				value: "hero_header",
	// 			},
	// 			{
	// 				label: "feature",
	// 				name: "hero_subtitle",
	// 				value: "hero_subtitle",
	// 			},
	// 			{
	// 				label: "No. of coins",
	// 				name: "hero_button",
	// 				value: "hero_button",
	// 			},
	// 			{
	// 				label: "cash prizes",
	// 				name: "hero_button",
	// 				value: "hero_button",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		name: "Gold",
	// 		children: [
	// 			{
	// 				label: "per day",
	// 				name: "hero_header",
	// 				value: "hero_header",
	// 			},
	// 			{
	// 				label: "per name",
	// 				name: "hero_subtitle",
	// 				value: "hero_subtitle",
	// 			},
	// 			{
	// 				label: "access hour",
	// 				name: "hero_button",
	// 				value: "hero_button",
	// 			},
	// 			{
	// 				label: "number of access",
	// 				name: "hero_header",
	// 				value: "hero_header",
	// 			},
	// 			{
	// 				label: "feature",
	// 				name: "hero_subtitle",
	// 				value: "hero_subtitle",
	// 			},
	// 			{
	// 				label: "No. of coins",
	// 				name: "hero_button",
	// 				value: "hero_button",
	// 			},
	// 			{
	// 				label: "cash prizes",
	// 				name: "hero_button",
	// 				value: "hero_button",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		name: "Ultimate",
	// 		children: [
	// 			{
	// 				label: "per day",
	// 				name: "hero_header",
	// 				value: "hero_header",
	// 			},
	// 			{
	// 				label: "per name",
	// 				name: "hero_subtitle",
	// 				value: "hero_subtitle",
	// 			},
	// 			{
	// 				label: "access hour",
	// 				name: "hero_button",
	// 				value: "hero_button",
	// 			},
	// 			{
	// 				label: "number of access",
	// 				name: "hero_header",
	// 				value: "hero_header",
	// 			},
	// 			{
	// 				label: "feature",
	// 				name: "hero_subtitle",
	// 				value: "hero_subtitle",
	// 			},
	// 			{
	// 				label: "No. of coins",
	// 				name: "hero_button",
	// 				value: "hero_button",
	// 			},
	// 			{
	// 				label: "cash prizes",
	// 				name: "hero_button",
	// 				value: "hero_button",
	// 			},
	// 		],
	// 	},
	// ];

	const { data, getPlan } = usePlanStore();

	useEffect(() => {
		apiCall("get", "/api/v1/plan", null, getPlan);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className="tw-w-1/2">
			<div className="tw-w-3/4 tw-space-y-4">
				{/* {sections?.map((section, i) => (
					<div key={i} className="">
						<div
							onClick={() => setActive(active === i ? null : i)}
							className="tw-h-14 tw-w-full tw-cursor-pointer tw-rounded-xl tw-border-0.5 tw-border-[#00555A4D] tw-px-4 tw-flex tw-justify-between tw-items-center">
							<div>
								<small className="tw-text-base tw-font-normal tw-text-black">
									{section?.name}
								</small>
							</div>
							<FaAngleDown />
						</div>
						{active === i && (
							<div className="tw-space-y-4 tw-mt-4">
								{section?.children?.map(it => (
									<RichTextEditor label={it?.label} />
								))}
							</div>
						)}
					</div>
				))} */}
				{data?.docs?.map((section, i) => (
					<div key={i} className="">
						<div
							onClick={() => setActive(active === i ? null : i)}
							className="tw-h-14 tw-w-full tw-cursor-pointer tw-rounded-xl tw-border-0.5 tw-border-[#00555A4D] tw-px-4 tw-flex tw-justify-between tw-items-center">
							<div>
								<small className="tw-text-base tw-font-normal tw-text-black tw-capitalize">
									{section?.title}
								</small>
							</div>
							<FaAngleDown />
						</div>
						{active === i && (
							<div className="tw-space-y-4 tw-mt-4">
								<PlanChildren item={section} />
							</div>
						)}
					</div>
				))}
			</div>
		</section>
	);
};

const PlanChildren = ({ item }) => {
	const init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		{ updatePlan } = usePlanStore();
	let handleSubmit = async e => {
		e?.preventDefault();
		if (!item?._id)
			if (!state?.title || !state?.planId)
				return toast.info("Resources title/content is required");
		setLoading(true);
		let newState = { ...state };
		if (state?.image)
			newState = {
				...newState,
				intendedFile: state?.image,
				intendedFileName: "image",
			};
		let { response, errArr, errMsg } = await apiCall(
			item?._id ? "put" : "post",
			`/api/v1/plan${item?._id ? `?plan=${item?._id}` : ""}`,
			newState,
			null,
			state?.image ? { "Content-Type": "multipart/form-data" } : null
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			setState({});
			updatePlan(response);
			return;
		}
		setLoading(false);
	};

	return (
		<>
			<div className="tw-space-y-4">
				<TextInput
					label={"Plan ID"}
					value={state?.planId || item?.planId}
					name="planId"
					onChange={textChange}
				/>
				<TextInput
					label={"Amount"}
					type={"number"}
					value={state?.amount || item?.amount}
					name="amount"
					onChange={textChange}
				/>
				<SelectInput
					label={"Per Amount"}
					options={[
						// { title: "Hour", _id: "hour" },
						{ title: "Day", _id: "day" },
						{ title: "Week", _id: "week" },
						{ title: "Month", _id: "month" },
						{ title: "Year", _id: "year" },
					]}
					value={state?.perAmount || item?.perAmount}
					onChange={textChange}
					name="perAmount"
				/>
				<TextInput
					label={"Access Length"}
					type={"number"}
					value={state?.lengthOfFrequency || item?.lengthOfFrequency}
					name="lengthOfFrequency"
					onChange={textChange}
				/>
				<SelectInput
					label={"Access Length Frequency"}
					options={[
						{ title: "Hour", _id: "hour" },
						{ title: "Day", _id: "day" },
						{ title: "Week", _id: "week" },
						{ title: "Month", _id: "month" },
						{ title: "Year", _id: "year" },
					]}
					value={state?.frequency || item?.frequency}
					onChange={textChange}
					name="frequency"
				/>
				<TextInput
					label={"Bonus Coin"}
					type={"number"}
					value={state?.noOfCoin || item?.noOfCoin}
					name="noOfCoin"
					onChange={textChange}
				/>
				<RichTextEditor
					label={"Other Features"}
					value={state?.features || item?.features}
					setState={e => setState({ ...state, features: e })}
				/>
			</div>
			<PrimaryBtn
				onClick={handleSubmit}
				loading={loading}
				text={"Update"}
				width={
					"tw-bg-scblue tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white"
				}
			/>
		</>
	);
};

export const Terms = ({ type, title, textarea }) => {
	const init = {},
		[state, setState] = useState(init),
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		{ getSetting, data } = useSettingStore(),
		{ convertCamelCase } = useContext(GlobalState),
		item = data;
	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.[type]) return toast.info(`Settings ${type} is required`);
		setLoading(true);
		let newState = { ...state };
		if (state?.image)
			newState = {
				...newState,
				intendedFile: state?.image,
				intendedFileName: "image",
			};
		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/settings`,
			newState,
			null,
			state?.image ? { "Content-Type": "multipart/form-data" } : null
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			getSetting(response);
			return;
		}
		setLoading(false);
	};
	// console.log({ item });
	return (
		<section className={`${!title ? "tw-w-1/2" : ""}`}>
			<div className="tw-space-y-4 tw-mb-3">
				{textarea ? (
					<>
						<TextInput
							label={title || convertCamelCase(type)}
							value={state?.[type] || item?.[type]}
							onChange={e => setState({ ...state, [type]: e?.target?.value })}
							name={type}
							type={typeof textarea === "string" ? textarea : "textarea"}
						/>
					</>
				) : (
					<RichTextEditor
						label={title || convertCamelCase(type)}
						value={state?.[type] || item?.[type]}
						setState={e => setState({ ...state, [type]: e })}
					/>
				)}
			</div>
			<PrimaryBtn
				onClick={handleSubmit}
				loading={loading}
				text={"Update"}
				width={
					"tw-bg-scblue tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white"
				}
			/>
		</section>
	);
};

const Settings = () => {
	return (
		<>
			<div className="tw-space-y-4 tw-mt-4">
				<Terms type={"questionsPerGame"} textarea="number" />
			</div>
			<div className="tw-space-y-4 tw-mt-4">
				<Terms type={"pointsPerScore"} textarea="number" />
			</div>
			<div className="tw-space-y-4 tw-mt-4">
				<Terms type={"passScore"} textarea="number" />
			</div>
		</>
	);
};

const Category = () => {
	const [active, setActive] = useState(null),
		[modal, setModal] = useState("");

	const { data, getCategory } = useCategoryStore();

	useEffect(() => {
		apiCall("get", "/api/v1/category", null, getCategory);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className="">
			<div className="tw-flex tw-mt-10 tw-justify-end">
				<TabBtn
					css={"tw-border-0.5 tw-border-[#0A1C2DB2]"}
					text={"Add New Category"}
					color={"#1C466C"}
					onClick={() => setModal("add")}
					bg={"white"}
				/>
			</div>
			<div className="tw-w-3/4 tw-space-y-4">
				{data?.docs?.map((section, i) => (
					<div key={i} className="">
						<div
							onClick={() => setActive(active === i ? null : i)}
							className="tw-h-14 tw-w-full tw-cursor-pointer tw-rounded-xl tw-border-0.5 tw-border-[#00555A4D] tw-px-4 tw-flex tw-justify-between tw-items-center">
							<div>
								<small className="tw-text-base tw-font-normal tw-text-black tw-capitalize">
									{section?.title}
								</small>
							</div>
							<FaAngleDown />
						</div>
						{active === i && (
							<div className="tw-space-y-4 tw-mt-4">
								<CategoryChildren item={section} />
							</div>
						)}
					</div>
				))}
			</div>
			{modal === "add" && (
				<AddNewCategoryModal handleClose={() => setModal("")} />
			)}
		</section>
	);
};

const CategoryChildren = ({ item }) => {
	const init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore(),
		{ updateCategory } = useCategoryStore();
	let handleSubmit = async e => {
		e?.preventDefault();
		if (!item?._id)
			if (!state?.title || !state?.homePageTitle || !state?.propertyPageTitle)
				return toast.info("Category titles' are required");
		setLoading(true);
		let newState = { ...state };
		if (state?.image)
			newState = {
				...newState,
				intendedFile: state?.image,
				intendedFileName: "image",
			};
		let { response, errArr, errMsg } = await apiCall(
			item?._id ? "put" : "post",
			`/api/v1/category${item?._id ? `?category=${item?._id}` : ""}`,
			newState,
			null,
			state?.image ? { "Content-Type": "multipart/form-data" } : null
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			setState({});
			updateCategory(response);
			return;
		}
		setLoading(false);
	};
	// homePageTitle
	// propertyPageTitle;
	return (
		<>
			<div className="tw-space-y-4">
				<TextInput
					label={"Title"}
					value={state?.title || item?.title}
					name="title"
					onChange={textChange}
				/>
				<TextInput
					label={"Category Home Page Title"}
					value={state?.homePageTitle || item?.homePageTitle}
					name="homePageTitle"
					onChange={textChange}
				/>
				<TextInput
					label={"Category Property Page Title"}
					value={state?.propertyPageTitle || item?.propertyPageTitle}
					name="propertyPageTitle"
					onChange={textChange}
				/>
				<RichTextEditor
					label={"Description"}
					value={state?.description || item?.description}
					setState={e => setState({ ...state, description: e })}
				/>
			</div>
			<PrimaryBtn
				onClick={handleSubmit}
				loading={loading}
				text={"Update"}
				width={
					"tw-bg-scblue tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white"
				}
			/>
		</>
	);
};

export default Contents;
