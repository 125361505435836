import React from "react";
import Notify from "../assets/notification.svg";
import Avatar from "../assets/avatar.svg";


const Header = ({ text }) => {
  return (
    <div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div>
          <strong className="tw-text-2xl tw-font-medium tw-text-black">
            {text}
          </strong>
        </div>
        <div className="tw-flex tw-gap-4">
          <img src={Notify} alt="" className="" />
          <img src={Avatar} alt="" className="" />
        </div>
      </div>
    </div>
  );
};

export default Header;
