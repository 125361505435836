import React from "react";
import Toilet from "../assets/toilet.svg";
import Car from "../assets/car.svg";
import DOMPurify from "dompurify";
import { TabBtn } from "./buttons";

export const createMarkup = html => {
	return {
		__html: DOMPurify.sanitize(html),
	};
};

const PropertiesCard = ({
	img,
	title,
	desc,
	room,
	toilet,
	parking,
	...restProps
}) => {
	return (
		<div className="tw-relative">
			<div className="tw-h- tw-w-full tw-rounded-xl tw-border-0.5 tw-border-[#00000033]">
				<img
					src={img}
					alt=""
					className="tw-rounded-t-xl tw-w-full tw-rounded-bl-[50px]"
				/>
				<div className="tw-h-full tw-p-4">
					<h5 className="tw-text-xl tw-font-medium tw-text-scblue">{title}</h5>
					<div className="tw-overflow-hidden">
						<div
							className="tw-text-xs tw-font-normal tw-leading-normal satoshi tw-text-scblue truncate"
							dangerouslySetInnerHTML={createMarkup(desc)}
						/>
						<div className="tw-mt-4 tw-flex tw-justify-between tw-items-center">
							<div>
								<strong className="tw-text-sm tw-font-bold tw-text-scblue">
									{room} <span className="tw-font-normal">Rooms</span>
								</strong>
							</div>
							<div className="tw-flex tw-gap-4 tw-items-center">
								<div className="tw-flex tw-gap-2 tw-items-center">
									<img src={Toilet} alt="" className="" />
									<small className="tw-text-xs tw-font-normal tw-text-scblue">
										{toilet}
									</small>
								</div>
								<div className="tw-flex tw-gap-2 tw-items-center">
									<img src={Car} alt="" className="" />
									<small className="tw-text-xs tw-font-normal tw-text-scblue">
										{parking}
									</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="tw-absolute tw-top-0 tw-right-0">
				<div className="tw-flex tw-items-center tw-p-1">
					{restProps?.update && (
						<TabBtn
							css={"tw-border-0.5 tw-border-[#0A1C2DB2] tw-me-2"}
							text={"Update"}
							color={"#1C466C"}
							onClick={() => restProps?.update()}
							bg={"white"}
						/>
					)}
					{restProps?.delete && (
						<TabBtn
							css={"tw-border-0.5 tw-border-[#0A1C2DB2] tw-me-2"}
							// css={"tw-border-0.5 tw-border-[#F51C3A]"}
							text={"Delete"}
							color={"#1C466C"}
							// color={"#F51C3A"}
							onClick={() => restProps?.delete()}
							bg={"white"}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
// linear-gradient(180deg, #00474C 0%, #005F65 100%)
export default PropertiesCard;
