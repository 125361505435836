import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../components/maincontainer";
import Header from "../../components/header";
import { TabBtn } from "../../components/buttons";
import Avatar from "../../assets/tableavatar.svg";
import useUserStore, { useAdminStore } from "../../data/Stores/userstore";
import useAuthStore, { apiCall } from "../../data/Stores/AuthStore";
import { LoadMore, MainPaginate } from "../../components/Pagination";
import moment from "moment";
import useSettingStore from "../../data/Stores/settingsstore";

const Members = () => {
	return (
		<div>
			<MainContainer>
				<Header text={"Players"} />
				<MainSection />
			</MainContainer>
		</div>
	);
};

const MainSection = () => {
	const [tab, setTab] = useState("registered"),
		{ user } = useAuthStore();
	return (
		<section className="tw-w-full tw-px-8 tw-mt-10">
			<div className="tw-flex tw-gap-6">
				<TabBtn
					onClick={() => setTab("registered")}
					text={"Registered Player"}
					css={
						tab === "registered"
							? "tw-border-none"
							: "tw-border-0.5 tw-border-[#0A1C2DB2]"
					}
					bg={tab === "registered" ? "#1C466C" : "white"}
					color={tab === "registered" ? "white" : "#1C466C"}
				/>
				<TabBtn
					onClick={() => setTab("leaderboard")}
					text={"Leaderboard"}
					css={
						tab === "leaderboard"
							? "tw-border-none"
							: "tw-border-0.5 tw-border-[#0A1C2DB2]"
					}
					bg={tab === "leaderboard" ? "#1C466C" : "white"}
					color={tab === "leaderboard" ? "white" : "#1C466C"}
				/>
				{user.privilege === "superadmin" && (
					<TabBtn
						onClick={() => setTab("admins")}
						text={"Admins"}
						css={
							tab === "admins"
								? "tw-border-none"
								: "tw-border-0.5 tw-border-[#0A1C2DB2]"
						}
						bg={tab === "admins" ? "#1C466C" : "white"}
						color={tab === "admins" ? "white" : "#1C466C"}
					/>
				)}
			</div>
			<div className="tw-mt-10">
				{tab === "registered" && <Registered />}
				{tab === "leaderboard" && <Leaderboard />}
				{tab === "admins" && <Registered privilege={"admin"} />}
			</div>
		</section>
	);
};

const Registered = ({ privilege }) => {
	const headers = ["SN.", "Name", "Date Reg."];

	let [datum, setDatum] = useState(null),
		[loading, setLoading] = useState(null),
		{ isFound, mainSearch, data, getUsers } = useUserStore(),
		{
			isFound: isFoundAdmin,
			mainSearch: mainSearchAdmin,
			data: dataAdmin,
			getUsers: getAdmins,
		} = useAdminStore(),
		[search] = useState(""),
		navigate = useNavigate();

	useEffect(() => {
		let dd = {};
		if (privilege === "admin") {
			if (isFoundAdmin) dd = mainSearchAdmin;
			else dd = dataAdmin;
		} else {
			if (isFound) dd = mainSearch;
			else dd = data;
		}
		setDatum(dd);
	}, [
		data,
		isFound,
		mainSearch,
		privilege,
		dataAdmin,
		mainSearchAdmin,
		isFoundAdmin,
	]);

	useEffect(() => {
		apiCall(
			"get",
			`/api/v1/user/manage-users?privilege=${privilege || "user"}`,
			null,
			privilege === "admin" ? getAdmins : getUsers
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [privilege]);

	const [range] = useState(10),
		[page, setPage] = useState(1),
		[itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	let handleLoadMore = async () => {
		setLoading("loadmore");

		await apiCall(
			"get",
			`/api/v1/user/manage-users?privilege=${privilege || "user"}&limit=${
				datum?.limit * datum?.nextPage
			}${search ? `&search=${search}` : ""}`,
			null,
			privilege === "admin" ? getAdmins : getUsers
		);
		setLoading(false);
	};

	return (
		<section className="">
			<div className="tw-w-full tw-border-0.5 tw-border-[#00555A80] tw-rounded-xl tw-p-10">
				<div className="tw-hidden lg:tw-block">
					<table className="tw-w-full">
						<thead>
							{headers?.map((it, i) => (
								<th
									className={`tw-text-sm tw-font-medium satoshi tw-text-[#06094F] ${
										i === 0 ? "tw-w-8" : "tw-w-20"
									}`}>
									{it}
								</th>
							))}
						</thead>
						<tbody>
							{currentItems?.map((item, i) => (
								<tr
									className={`tw-border-b tw-border-b-[#CDDCE0] tw-h-16 ${
										privilege ? "tw-cursor-pointer" : ""
									}`}
									key={i}
									onClick={
										privilege
											? () => {
													navigate("manage-stat", { state: item });
											  }
											: () => {}
									}>
									<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
										{i + 1}
									</td>
									<td>
										<div className="tw-flex tw-gap-2 tw-items-center">
											<img src={Avatar} alt="" className="" />
											<div>
												<small className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
													{item?.firstName} {item?.lastName}
												</small>
											</div>
										</div>
									</td>
									<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
										{moment(item?.createdAt)?.format("DD-MM-YYYY")}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="tw-block lg:tw-hidden">
					<div className="tw-space-y-4">
						{currentItems?.map((it, i) => (
							<div
								key={i}
								onClick={
									privilege
										? () => {
												navigate("manage-stat", { state: it });
										  }
										: () => {}
								}
								className={`bordered tw-h-20 tw-px-4 tw-w-full tw-flex tw-justify-between tw-items-center ${
									privilege ? "tw-cursor-pointer" : ""
								}`}>
								<div>
									<img src={Avatar} alt="" className="" />
									<div className="tw-mt-2">
										<small className="tw-text-sm tw-font-normal tw-text-plain nexa">
											{it?.firstName} {it?.lastName}
										</small>
									</div>
								</div>
								<div>
									<div className="tw-mt-22">
										{" "}
										<small className="tw-text-sm tw-font-normal tw-text-plain nexa">
											{moment(it?.createdAt)?.format("DD-MM-YYYY")}
										</small>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="tw-mt-4 tw-flex tw-justify-center">
					<LoadMore
						next={page === pageCount && datum?.hasNextPage}
						loading={loading === "loadmore"}
						handleLoadMore={handleLoadMore}
					/>
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
		</section>
	);
};

const Leaderboard = () => {
	const headers = ["SN.", "Name", "Score", "Position"],
		{ getLeaderboard, leaderboard } = useSettingStore();

	useEffect(() => {
		apiCall("get", "/api/v1/question/leaderboard", null, getLeaderboard);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className="">
			<div className="tw-w-full tw-border-0.5 tw-border-[#00555A80] tw-rounded-xl tw-p-10">
				<div className="tw-hidden lg:tw-block">
					<table className="tw-w-full">
						<thead>
							{headers?.map((it, i) => (
								<th
									className={`tw-text-sm tw-font-medium satoshi tw-text-[#06094F] ${
										i === 0 ? "tw-w-8" : "tw-w-20"
									}`}>
									{it}
								</th>
							))}
						</thead>
						<tbody>
							{leaderboard?.map((item, i) => (
								<tr
									key={i}
									className={`tw-border-b tw-border-b-[#CDDCE0] tw-h-16`}>
									<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
										{i + 1}
									</td>
									<td>
										<div className="tw-flex tw-gap-2 tw-items-center">
											<img src={Avatar} alt="" className="" />
											<div>
												<small className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
													{item?.user?.firstName} {item?.user?.lastName}
												</small>
											</div>
										</div>
									</td>
									<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
										{item?.data}
									</td>
									<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
										<RankManager score={i} />
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="tw-block lg:tw-hidden">
					<div className="tw-space-y-4">
						{leaderboard?.map((it, i) => (
							<div
								key={i}
								className={`bordered tw-h-20 tw-px-4 tw-w-full tw-flex tw-justify-between tw-items-center`}>
								<div>
									<img src={Avatar} alt="" className="" />
									<div className="tw-mt-2">
										<small className="tw-text-sm tw-font-normal tw-text-plain nexa">
											{it?.user?.firstName} {it?.user?.lastName}
										</small>
									</div>
								</div>
								<div>
									<div className="tw-mt-22">
										{" "}
										<small className="tw-text-sm tw-font-normal tw-text-plain nexa">
											{it?.data}
										</small>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Members;

export const RankManager = ({ score }) => {
	let [grader, setGrader] = useState("");

	let hostEndsWith = (host, ends) => {
		let value = false;
		value = ends?.some(element => {
			return host?.toString()?.endsWith(element?.toString());
		});
		return value;
	};
	useEffect(() => {
		if (score) {
			let finder = score + 1,
				finder2 = finder?.toString();
			if (hostEndsWith(finder2, ["11", "12", "13"])) {
				setGrader("th");
			} else if (finder2?.endsWith("1")) setGrader("st");
			else if (finder2?.endsWith("2")) setGrader("nd");
			else if (finder2?.endsWith("3")) setGrader("rd");
			else setGrader("th");
		} else if (score === 0) setGrader("st");
	}, [score]);

	return (
		<>
			{score + 1}
			{grader}
		</>
	);
};