/* eslint-disable react/prop-types */
import { createContext } from "react";
import { useState } from "react";
import DashboardIcon from "../assets/dashboard.svg";
import PropertiesIcon from "../assets/properties.svg";
import MembersIcon from "../assets/members.svg";
import ContentsIcon from "../assets/contents.svg";
import SubscriptionsIcon from "../assets/subscriptions.svg";
import useAuthStore from "./Stores/AuthStore";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
	const [nav, setNav] = useState(false);
	let handleCapitalize = word => {
		let splitter = word.trim().split(" ");
		let firstCap = splitter[0].split("");
		let remaining = splitter.slice(1, splitter.length).join(" ");

		let firstCapOne = firstCap[0].toUpperCase();
		let firstCapTwo = firstCap.slice(1, firstCap.length).join("");

		let joinFirst = `${firstCapOne}${firstCapTwo}`;

		return `${joinFirst} ${remaining}`;
	};

	let numberWithCommas = x => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const toggleNav = () => {
		setNav(!nav);
	};

	let auth = useAuthStore();

	let sidebarList = [
		{
			name: "Dashboard",
			url: "/dashboard",
			icon: DashboardIcon,
		},
		{
			name: "Properties",
			url: "/properties",
			icon: PropertiesIcon,
		},
		{
			name: "members",
			url: "/members",
			icon: MembersIcon,
		},
		{
			name: "contents",
			url: "/contents",
			icon: ContentsIcon,
		},
		{
			name: "subscriptions",
			url: "/subscriptions",
			icon: SubscriptionsIcon,
		},
	];

  let nairaSign = <span className="fontInherit">&#8358;</span>;
	let nairaSignNeutral = "₦";

	let convertCamelCase = str => {
		const result = str.replace(/([A-Z])/g, " $1");
		const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

		return finalResult;
	};

	const state = {
		handleCapitalize,

		numberWithCommas,

		sidebarList,
		auth,
		nav,
		toggleNav,
		nairaSign,
		nairaSignNeutral,
		convertCamelCase,
	};

	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
