import React, { useState } from "react";
import useAuthStore from "../../data/Stores/AuthStore";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../logo";
import { AiOutlineMenu } from "react-icons/ai";
import DashboardIcon from "../../assets/dashboard.svg";
import PropertiesIcon from "../../assets/properties.svg";
import MembersIcon from "../../assets/members.svg";
import ContentsIcon from "../../assets/contents.svg";
import SubscriptionsIcon from "../../assets/subscriptions.svg";
import { MdOutlineQuiz } from "react-icons/md";

const Sidebar = ({ children }) => {
  const [nav, setNav] = useState(false);
  const toggleNav = () => {
    setNav(!nav);
  };
  let sidebarList = [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: DashboardIcon,
    },
    {
      name: "Properties",
      url: "/properties",
      icon: PropertiesIcon,
    },
    {
      name: "members",
      url: "/members",
      icon: MembersIcon,
    },
    {
      name: "contents",
      url: "/contents",
      icon: ContentsIcon,
    },
    {
      name: "subscriptions",
      url: "/subscriptions",
      icon: SubscriptionsIcon,
    },
    {
      name: "trivia",
      url: "/trivia",
      icon: ContentsIcon,
    },
  ];
  const { isAuth } = useAuthStore(),
    navigate = useNavigate(),
    location = useLocation(),
    currentPath = location.pathname;
  const handleItemClick = (url) => {
    navigate(url);
  };
  return (
    <div className="">
      {isAuth && (
        <>
          <button
            type="button"
            className="tw-inline-flex tw-items-center tw-p-2 tw-mt-2 tw-ml-3 tw-text-sm tw-text-gray-500 tw-rounded-lg sm:tw-hidden hover:tw-bg-gray-100 focus:tw-outline-none tw-overflow-visible focus:tw-ring-2 focus:tw-ring-gray-200"
            onClick={toggleNav}
          >
            <span className="tw-sr-only">Open sidebar</span>

            <AiOutlineMenu size={20} />
          </button>
          <div
            className={`tw-fixed md:tw-hidden tw-top-0 tw-left-0 tw-z-40 tw-w-full tw-h-screen tw-transition-transform tw-bg-black tw-opacity-10 ${
              nav
                ? "tw-translate-x-0"
                : "-tw-translate-x-full md:tw-translate-x-0"
            }`}
            onClick={toggleNav}
          ></div>
          <aside
            className={`tw-fixed tw-shadow-sm tw-top-0 tw-left-0 tw-z-40 tw-w-64 tw-bg-primary tw-h-screen tw-pb-8 tw-transition-transform ${
              nav
                ? "tw-translate-x-0"
                : "-tw-translate-x-full md:tw-translate-x-0"
            }`}
          >
            <div className="tw-h-screen tw-relative tw-py-4 tw-overflow-y-scroll noScroll">
              <div className="tw-flex tw-my-6 tw-justify-center">
                <Logo />
              </div>
              <div className="tw-flex tw-justify-center">
                <div className="tw-space-y-6 tw-mt-6">
                  {sidebarList?.map((item, i) => (
                    <div
                      onClick={() => handleItemClick(item.url)}
                      key={i}
                      className={`tw-flex tw-h-10 tw-w-40 tw-rounded-lg tw-cursor-pointer tw-items-center tw-gap-3 tw-px-3 ${
                        currentPath === item?.url ||
                        currentPath.includes(item?.url)
                          ? "tw-bg-scblue tw-transition-all tw-duration-500 tw-ease-in-out"
                          : "tw-bg-transparent tw-border-0.5 tw-border-[#FFFFFF66]"
                      }`}
                    >
                      <img src={item?.icon} alt="" className="" />
                      <small
                        className={`tw-text-sm satoshi tw-capitalize tw-font-medium tw-text-white`}
                      >
                        {item?.name}
                      </small>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </aside>
        </>
      )}
      <div className={`${isAuth ? "sm:tw-ml-64 tw-bg-primary tw-p-8" : ""}`}>
        <div className={`${isAuth ? "" : ""}`}>{children}</div>
      </div>
    </div>
  );
};

export default Sidebar;
