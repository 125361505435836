import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PageRender from "./PageRender";
import useAuthStore from "./data/Stores/AuthStore";
import MainHome from "./pages/home";
import { ToastContainer } from "react-toastify";
import Login from "./screens/login";
import Sidebar from "./components/sidebar";
import useErrorStore from "./data/Stores/errorstore";
import ModalContainer from "./components/modals/modalcontainer";

const Routers = () => {
	const { isAuth } = useAuthStore(),
		{ error, clearErrors } = useErrorStore();
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);
	return (
		<>
			<ToastContainer position="top-right" />
			<Sidebar>
				<Routes>
					<Route path="/" element={isAuth ? <MainHome /> : <Login />} />
					<Route path="/:page" element={<PageRender />} />
					<Route path="/:page/:id" element={<PageRender />} />
					<Route path="/:page/:id/:step" element={<PageRender />} />
				</Routes>
			</Sidebar>
			{error?.length > 0 && (
				<ModalContainer handleClose={() => clearErrors()}>
					<h2 className="tw-text-2xl tw-font-bold satoshi tw-text-red-600">
						Error
					</h2>
					{error?.map((item, i) => (
						<p key={i} className="tw-fw-semibold tw-my-2">
							<span className="fontInherit me-2">
								{error?.length !== 1 && <>{i + 1}.</>}
							</span>{" "}
							{item?.message || item?.msg || null}
						</p>
					))}
				</ModalContainer>
			)}
		</>
	);
};

export default Routers;
