import React from "react";

const MainContainer = ({ children }) => {
  return (
    <div>
      <div className="tw-w-full tw-min-h-screen tw-overflow-visible tw-rounded-3xl tw-bg-[#F9FBFF] tw-p-12">
        {children}
      </div>
    </div>
  );
};

export default MainContainer;
