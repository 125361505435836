import React, { useState, useEffect } from "react";
import MainContainer from "../../components/maincontainer";
import Header from "../../components/header";
import PrimaryBtn, { TabBtn } from "../../components/buttons";
import PropertiesCard from "../../components/cards";
import AddNewPropertyModal from "../../components/modals/add-new-property-modal";
import useCategoryStore from "../../data/Stores/categorystore";
import { apiCall } from "../../data/Stores/AuthStore";
import useListingStore from "../../data/Stores/listingStore";
import { LoadMore, MainPaginate } from "../../components/Pagination";
import ModalContainer from "../../components/modals/modalcontainer";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";

const Properties = () => {
	return (
		<div>
			<MainContainer>
				<Header text={"Properties"} />
				<MainSection />
			</MainContainer>
		</div>
	);
};

const MainSection = () => {
	const [tab, setTab] = useState("all"),
		[modal, setModal] = useState(""),
		[loading, setLoading] = useState(null),
		[datum, setDatum] = useState(null);

	const category = useCategoryStore(),
		{ data, getListing, deleteListing } = useListingStore(),
		listing = useListingStore(),
		[item, setItem] = useState(""),
		[isDelete, setIsDelete] = useState(""),
		{ returnErrors } = useErrorStore();
	let handleSubmit = async (e, se) => {
		e?.preventDefault();
		setLoading("delete");
		let { response, errArr, errMsg } = await apiCall(
			"delete",
			`/api/v1/listing?listing=${isDelete?._id}`,
			isDelete
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			deleteListing({
				data: isDelete,
				adder: category?.data?.docs?.find(it => it?._id === isDelete?.category)
					?.title,
			});
			setIsDelete(null);
			return;
		}
		setLoading(false);
	}

	useEffect(() => {
		apiCall("get", "/api/v1/category", null, category?.getCategory);
		apiCall("get", "/api/v1/listing", null, getListing);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (tab && tab !== "all") {
			let getFunc = async () => {
				let { response } = await apiCall(
					"get",
					`/api/v1/listing?category=${tab}`
				);
				getListing({
					...response,
					adder: category?.data?.docs?.find(it => it?._id === tab)?.title,
				});
			};
			getFunc();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab]);

	useEffect(() => {
		if (tab === "all") setDatum(data);
		else {
			let finder = category?.data?.docs?.find(it => it?._id === tab);
			if (finder && listing?.[finder?.title]) {
				setDatum(listing?.[finder?.title]);
			} else {
				setDatum(null);
			}
		}
	}, [category?.data?.docs, data, listing, tab]);

	const [range] = useState(10),
		[page, setPage] = useState(1),
		[itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
		setPage(1 + event?.selected);
	};

	let handleLoadMore = async () => {
		setLoading("loadmore");

		let { response } = await apiCall(
			"get",
			`/api/v1/listing?limit=${datum?.limit * datum?.nextPage}${
				tab && tab !== "all" ? `&category=${tab}` : ""
			}`
		);
		getListing(
			tab && tab !== "all"
				? {
						...response,
						adder: category?.data?.docs?.find(it => it?._id === tab)?.title,
				  }
				: response
		);
		setLoading(false);
	};

	return (
		<section className="tw-w-full tw-bg-white tw-p-8">
			<div className="tw-flex tw-mt-10 tw-justify-between">
				<div className="tw-flex tw-gap-6">
					<TabBtn
						onClick={() => setTab("all")}
						text={"All"}
						css={
							tab === "all"
								? "tw-border-none"
								: "tw-border-0.5 tw-border-[#0A1C2DB2]"
						}
						bg={tab === "all" ? "#1C466C" : "white"}
						color={tab === "all" ? "white" : "#1C466C"}
					/>
					{category?.data?.docs?.map((item, i) => (
						<TabBtn
							key={i}
							onClick={() => setTab(item?._id)}
							text={item?.propertyPageTitle}
							css={
								tab === item?._id
									? "tw-border-none"
									: "tw-border-0.5 tw-border-[#0A1C2DB2]"
							}
							bg={tab === item?._id ? "#1C466C" : "white"}
							color={tab === item?._id ? "white" : "#1C466C"}
						/>
					))}
				</div>
				<TabBtn
					css={"tw-border-0.5 tw-border-[#0A1C2DB2]"}
					text={"Add New Property"}
					color={"#1C466C"}
					onClick={() => setModal("add")}
					bg={"white"}
				/>
			</div>
			<div className="tw-mt-12 tw-grid tw-grid-cols-3 tw-gap-6">
				{currentItems?.map((it, i) => (
					<PropertiesCard
						img={it?.image?.url}
						title={it?.title}
						desc={it?.desc}
						room={it?.noOfBedroom}
						toilet={it?.noOfBathRoom}
						parking={it?.noOfParking}
						key={i}
						delete={() => {
							setIsDelete(it);
						}}
						update={() => {
							setItem(it);
							setModal("add");
						}}
					/>
				))}
			</div>
			<div className="tw-mt-4 tw-flex tw-justify-center">
				<LoadMore
					next={page === pageCount && datum?.hasNextPage}
					loading={loading === "loadmore"}
					handleLoadMore={handleLoadMore}
				/>
			</div>
			{pageCount > 1 && (
				<MainPaginate pageCount={pageCount} handlePageClick={handlePageClick} />
			)}
			{modal === "add" && (
				<AddNewPropertyModal
					handleClose={() => {
						setModal("");
						setItem("");
					}}
					item={item}
				/>
			)}
			{isDelete && (
				<ModalContainer handleClose={() => setIsDelete(null)}>
					<form>
						<h3 className="tw-text-2xl tw-font-semibold inter tw-text-scblue">
							Delete {isDelete?.title} Property
						</h3>
						<div className="tw-mt-10 tw-flex tw-items-center tw-justify-center">
							<div className="tw-grid tw-grid-cols-2 tw-gap-2">
								<PrimaryBtn
									width={"tw-w-full"}
									bg={"#1C466C"}
									text={"Delete"}
									color={"white"}
									onClick={handleSubmit}
									loading={loading === "delete"}
								/>
								<TabBtn
									css={"tw-border-0.5 tw-border-[#0A1C2DB2]"}
									text={"Cancel"}
									color={"#1C466C"}
									onClick={() => setIsDelete(null)}
									bg={"white"}
								/>
							</div>
						</div>
					</form>
				</ModalContainer>
			)}
		</section>
	);
};

export default Properties;
