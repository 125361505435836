import React, { useState } from "react";
import TextInput, { PasswordInput } from "../components/inputs";
import { useNavigate } from "react-router-dom";
import useAuthStore, { apiCall } from "../data/Stores/AuthStore";
import useGenFetcher from "../data/useGenFetcher";
import { toast } from "react-toastify";
import useErrorStore from "../data/Stores/errorstore";
import PrimaryBtn from "../components/buttons";

const Login = () => {
	const navigate = useNavigate();
	const { login } = useAuthStore(),
		{ loadUser } = useGenFetcher();

	const init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore();
	let handleSubmit = async e => {
		e?.preventDefault();
		let errArr2 = [];
		if (!state?.telephone) errArr2?.push("Phone number is required");
		if (!state?.password) errArr2?.push("Password is required");
		if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
		setLoading(true);
		let newState = { ...state };

		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/user/login`,
			newState
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			login(response);
			loadUser();
			navigate("/");
			return;
		}
		setLoading(false);
	};

	return (
		<div className="tw-w-full tw-h-screen  loginbg">
			<div className="tw-w-full tw-h-full tw-bg-[#FFFFFF99] tw-flex tw-items-center tw-justify-center">
				<div className=" lg:tw-w-1/3 tw-5/6 tw-min-h-96 tw-overflow-y-visible tw-rounded-3xl tw-p-8 tw-bg-white">
					<h1 className="tw-text-xm bolder tw-text-[#002224] tw-capitalize">
						Log in
					</h1>
					<p className="tw-text-base tw-font-medium tw-text-scgreen">
						nobis est eligendi optio cumque nihil impedit quo{" "}
					</p>
					<form
						onSubmit={handleSubmit}
						action=""
						className="tw-mt-10 tw-w-full">
						<div className="tw-space-y-4">
							<TextInput
								label={"Phone Number"}
								type={"tel"}
								value={state?.telephone}
								name="telephone"
								onChange={textChange}
								maxLength={11}
							/>
							<PasswordInput
								label={"Password"}
								value={state?.password}
								name="password"
								onChange={textChange}
							/>
							<div className="tw-flex tw-justify-between tw-items-center">
								<div className="tw-flex tw-items-center tw-gap-2">
									<input
										type="checkbox"
										name=""
										className="tw-size-4 tw-rounded-sm"
										id=""
									/>
									<small className="tw-text-sm tw-font-normal tw-text-scgreen">
										Remember Me
									</small>
								</div>
								<small className="tw-text-sm tw-cursor-pointer tw-font-normal tw-text-scgreen">
									Forgot Password?
								</small>
							</div>
						</div>
						<div className="tw-mt-12">
							{/* <button className="tw-bg-scblue tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white">
								Login
							</button> */}
							<PrimaryBtn
								onClick={handleSubmit}
								loading={loading}
								text={"Login"}
								width={
									"tw-bg-scblue tw-w-full tw-h-12 tw-rounded-xl tw-font-medium tw-text-base tw-text-white"
								}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Login;
