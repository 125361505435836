import React, { useEffect, useState } from "react";
import MainContainer from "../../components/maincontainer";
import Header from "../../components/header";
import Chart from "../../assets/chart.svg";
import { GrDown } from "react-icons/gr";
import PropertiesCard from "../../components/cards";
import { useNavigate } from "react-router-dom";
import useListingStore from "../../data/Stores/listingStore";
import { apiCall } from "../../data/Stores/AuthStore";

const Dashboard = () => {
	// const Properties = [
	// 	{
	// 		title: "for Sale",
	// 		details: [
	// 			{ title: "total no", value: "563" },
	// 			{ title: "sold", value: "199" },
	// 			{ title: "on sale", value: "344" },
	// 		],
	// 	},
	// 	{
	// 		title: "for Rent",
	// 		details: [
	// 			{ title: "total no", value: "563" },
	// 			{ title: "sold", value: "199" },
	// 			{ title: "on sale", value: "344" },
	// 		],
	// 	},
	// ];

	const { dashboard, getDashboard } = useListingStore(),
		[datum, setDatum] = useState(null);

	useEffect(() => {
		apiCall("get", "/api/v1/listing/dashboard", null, getDashboard);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (dashboard) {
			let newD = [];

			for (let d = 0; d < dashboard.length; d++) {
				let element = dashboard[d];
				newD?.push({
					title: element?.propertyPageTitle,
					details: [
						{ title: "total no", value: element?.total },
						{ title: "month created", value: element?.month },
						{ title: "viewed", value: element?.views },
					],
				});
			}
			setDatum(newD);
		}
	}, [dashboard]);

	return (
		<div>
			<MainContainer>
				<Header text={"Dashboard"} />
				<PropertiesSection Properties={datum} />
				<GraphSection />
				<MainPropertiesSection />
			</MainContainer>
		</div>
	);
};

const PropertiesSection = ({ Properties }) => {
	return (
		<section>
			<div className="tw-mt-10 tw-grid tw-grid-cols-2 tw-gap-8">
				{Properties?.map((property, i) => (
					<div key={i} className="tw-bg-white tw-h-56 tw-rounded-xl tw-p-8">
						<h3 className="tw-text-base tw-font-bold tw-text-black">
							Properties {property?.title}
						</h3>
						<div className="tw-mt-2 tw-flex tw-gap-12">
							<img src={Chart} alt="" className="" />
							<div className="tw-w-full">
								{property?.details?.map((detail, index) => (
									<div
										key={index}
										className="tw-flex tw-space-y-5 tw-justify-between tw-items-center">
										<div>
											<small className="tw-text-base tw-capitalize tw-font-normal tw-text-black">
												{detail?.title}
											</small>
										</div>
										<strong className="tw-text-base tw-font-bold tw-text-black">
											{detail?.value}
										</strong>
									</div>
								))}
							</div>
						</div>
					</div>
				))}
			</div>
		</section>
	);
};

const GraphSection = () => {
	return (
		<section className="tw-w-full tw-mt-16 tw-bg-white tw-rounded-xl tw-p-8">
			<div className="tw-flex tw-justify-between tw-items-center">
				<h4 className="tw-text-xl tw-font-bold tw-text-scblue">
					Sale and Rent of Properties Monthly
				</h4>
				<div className="tw-flex tw-gap-3 tw-items-center">
					<div>
						<small className="tw-text-sm tw-font-medium tw-text-[#06094F]">
							Monthly - year 2023
						</small>
					</div>
					<GrDown />
				</div>
			</div>
		</section>
	);
};

const MainPropertiesSection = () => {
	const navigate = useNavigate();

	const { data, getListing } = useListingStore();

	useEffect(() => {
		apiCall("get", "/api/v1/listing", null, getListing);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className="tw-w-full tw-mt-16 tw-bg-white tw-rounded-xl tw-p-8">
			<div className="tw-flex tw-justify-between tw-items-center">
				<h4 className="tw-text-xl tw-font-bold tw-text-scblue">
					Recents Addings
				</h4>
				<div className="">
					<small
						onClick={() => navigate("/properties")}
						className="tw-text-base tw-cursor-pointer tw-border-b-2 tw-border-b-scblue tw-font-bold tw-text-scblue tw-pb-">
						See all
					</small>
				</div>
			</div>
			<div className="tw-mt-8 tw-grid tw-grid-cols-3 tw-gap-6">
				{data?.docs?.slice(0, 3)?.map((it, i) => (
					<PropertiesCard
						img={it?.image?.url}
						title={it?.title}
						desc={it?.content}
						room={it?.noOfBedroom}
						toilet={it?.noOfBathRoom}
						parking={it?.noOfParking}
						key={i}
					/>
				))}
			</div>
		</section>
	);
};

export default Dashboard;
