import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/header";
import MainContainer from "../../components/maincontainer";
import useAuthStore from "../../data/Stores/AuthStore";
import { useAdminStore } from "../../data/Stores/userstore";
import { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../data/Context";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import useErrorStore from "../../data/Stores/errorstore";
import { MainPaginate } from "../../components/Pagination";
import { MainQuestion } from "../trivia";
import PrimaryBtn, { TabBtn } from "../../components/buttons";
import ModalContainer from "../../components/modals/modalcontainer";

const AdminStat = () => {
	return (
		<div>
			<MainContainer>
				<Header text={"Admin Stat"} />
				<MainSection />
			</MainContainer>
		</div>
	);
};

const MainSection = () => {
	let items = useLocation()?.state;
	let [state, setState] = useState(items),
		{ user } = useAuthStore(),
		candidates = useAdminStore(),
		navigate = useNavigate(),
		{ numberWithCommas } = useContext(GlobalState),
		[modal, setModal] = useState(""),
		[loading, setLoading] = useState(null),
		[datum, setDatum] = useState(null),
		[datumAdmin, setDatumAdmin] = useState(null),
		textChange = e => {
			let { name, value } = e?.target;
			setDatum(prev => {
				let prevData = { ...prev };
				prevData[name] = value;
				return prevData;
			});
		},
		{ returnErrors } = useErrorStore(),
		handleSubmit = async e => {
			try {
				if (e) e?.preventDefault();
				if (datum) if (Object.entries(datum)?.length !== 2) return;
				setLoading(true);
				let res5 = await axios.get(
					`/api/v1/question/manage-questions?createdBy=${state?._id}${
						datum?.startDate ? `&startDate=${datum?.startDate}` : ""
					}${datum?.endDate ? `&endDate=${datum?.endDate}` : ""}${
						!datum?.endDate && !datum?.startDate
							? `&actualDate=${moment().format("YYYY-MM-DD")}`
							: ""
					}`
				);
				setDatumAdmin(res5?.data?.data);
				console.log({ adminStatistics: res5?.data });
				setLoading(false);
				setDatum({});
				setModal("");
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);

				let error = err.response?.data?.error;
				if (error) {
					setLoading(false);
					return returnErrors({ error, status: err?.response?.status });
				} else {
					setLoading(false);
					return toast.error(err?.response?.data?.message);
				}
			}
		};

	useEffect(() => {
		if (user?.privilege !== "superadmin") navigate(-1);
	}, [user?.privilege, navigate]);

	useEffect(() => {
		candidates?.data?.docs?.map(it => it?._id === items?._id && setState(it));
	}, [candidates?.data, items]);

	useEffect(() => {
		handleSubmit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	let [range] = useState(10);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	const currentItems = datumAdmin?.questions?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datumAdmin?.questions?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datumAdmin?.questions?.length;
		setItemOffset(newOffset);
	};
	return (
		<section className="tw-w-full tw-px-8 tw-mt-10">
			<div className="tw-flex tw-mt-10 tw-justify-between">
				<div className="tw-flex tw-gap-3 tw-flex-col">
					<h2 className="tw-text-xl tw-text-black tw-font-normal work">
						{state?.firstName} {state?.lastName}
					</h2>
					<h2 className="tw-text-xl tw-text-black tw-font-normal work">
						Question Statistics:{" "}
						{numberWithCommas(datumAdmin?.data?.totalDocs || 0)}
					</h2>
				</div>
				<TabBtn
					css={"tw-border-0.5 tw-border-[#0A1C2DB2]"}
					text={"Admin Stat"}
					color={"#1C466C"}
					onClick={() => setModal("add")}
					bg={"white"}
				/>
			</div>
			<div className="tw-mt-10">
				<div className="tw-mt-6 tw-grid lg:tw-grid-cols-2 tw-gap-5">
					{currentItems?.map((q, i) => (
						<MainQuestion q={q} key={i} i={i} noHover />
					))}
				</div>
				{pageCount > 1 && (
					<MainPaginate
						pageCount={pageCount}
						handlePageClick={handlePageClick}
					/>
				)}
			</div>
			{modal && (
				<ModalContainer height={true} handleClose={() => setModal(false)}>
					<div className="tw-flex tw-justify-between tw-mt-4">
						<h2 className="segoe tw-text-xl tw-font-bold tw-text-black tw-capitalize">
							Stat Range
						</h2>
					</div>
					<form className="tw-mt-5">
						<div className="tw-space-y-4">
							<div className="">
								<label
									htmlFor="input"
									className=" tw-font-semibold tw-text-[20px]">
									Start Date
								</label>
								<input
									type="date"
									id="input"
									className="tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-my-4 tw-shadow"
									value={datum?.startDate}
									onChange={textChange}
									max={moment().format("YYYY-MM-DD")}
									name={"startDate"}
								/>
							</div>
							{datum?.startDate && (
								<div className="">
									<label
										htmlFor="input"
										className=" tw-font-semibold tw-text-[20px]">
										End Date
									</label>
									<input
										type="date"
										id="input"
										className="tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-my-4 tw-shadow"
										value={datum?.endDate}
										onChange={textChange}
										name={"endDate"}
										max={moment().format("YYYY-MM-DD")}
										min={moment(datum?.startDate).format("YYYY-MM-DD")}
									/>
								</div>
							)}
						</div>
						<div className="tw-mt-10">
							<PrimaryBtn
								width={"tw-w-full"}
								bg={"#1C466C"}
								text={"Send"}
								color={"white"}
								onClick={handleSubmit}
								loading={loading}
							/>
						</div>
					</form>
				</ModalContainer>
			)}
		</section>
	);
};

export default AdminStat;
